/* eslint-disable react-hooks/exhaustive-deps */

import { MapView } from "@aws-amplify/ui-react-geo";
import { API } from "aws-amplify";
import moment from "moment";
import printJS from 'print-js';
import React, { useEffect, useRef, useState } from "react";
import BarcodeReader from "react-barcode-reader";
import {
	Accordion,
	Button,
	Col,
	Container,
	ListGroup,
	Modal,
	Row,
	Table,
} from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Form from "react-bootstrap/Form";
import Flatpickr from 'react-flatpickr';
import ReactGA from "react-ga4";
import { toast } from "react-hot-toast";
import ImageGallery from "react-image-gallery";
import PhoneInput from "react-phone-input-2";
import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import StarRatings from "react-star-ratings";
import swal from "sweetalert";
import uniqid from "uniqid";
import awsconfig from "../../aws-exports";
import { AddressView, GoogleAddressField } from "../../components/Address";
import { Card, MultiButtonCard } from "../../components/Card";
import FormLabel from "../../components/FormLabel";
import ListGroupIconItem from '../../components/ListGroupIconItem';
import ListGroupItem from "../../components/ListGroupItem";
import {
	ActualDeliveredMarker,
	DriverMarker,
	OutForReturnMarker,
	ReturnedMarker,
	ShipmentMarker,
	ShipperMarker,
} from "../../components/MapMarker";
import SideDrawer from "../../components/SideDrawer";
import Spinner from "../../components/Spinner";
import { MapToggleButton } from "../../components/TableFilter";
import ShipmentStatus from "../../data/shipment-status.json";
import SignatureType from "../../data/signature-type.json";
import StorageType from "../../data/storage-type.json";
import {
	FormError,
	PAGE_SIZE,
	PAGE_TITLE,
	formatNumber,
	getDistance,
	getDistanceFromHere,
	getLocationTimezone,
	handleApiError,
	isEditor,
	isOwner,
	isPOBoxAddress,
	isValidEmail,
	isValidPhone,
	isViewer,
	statusBadgeColor,
	statusButtonColor,
	statusIcon,
	toLocalDate,
	toLocalDateTime,
	toLocalTime
} from "../../helpers";
import { processBilling } from "../../helpers/processBilling";
import DownloadPOD from "./DownloadPOD";
import PrintLabel from "./PrintLabel";

const QRCode = require("qrcode.react");

const getShipmentQuery = /* GraphQL */ `
  query GetShipment($id: ID!) {
    getShipment(id: $id) {
      id
      number
	  authCode
      shipFrom
      shipTo
      weight
      distance
      status
      services
      deliveryInstructions
      items
      billing
	  billing_carrier
	  billing_shipper
      attachment
	  packageCount
	  customerId
	  boxId
		box{
			number
			name
		}
	  isGreenPhox
      shipperId
	  shipper{
		shipperGroup{
			id
			name
			alias
		}
		settings{
			items {
				key
				value
			}
		}
	  }
	  authCode
      createdTime

      expectedPickupTime
      actualPickupTime
      pickupLocation {
        latitude
        longitude
      }

      expectedDeliveryTime
      actualDeliveryTime
      deliveryLocation {
        latitude
        longitude
      }

      outForReturnTime
      returnedTime
      returnLocation {
        latitude
        longitude
      }
	  communicationLogs {
          items {
        from
        to
        subject
        status
        type
		events	
        updatedAt
		shipmentId
		createdTime
		shipperGroup {
        name
       }
       shipment {
        number
       }
      }
}
      extId
      extTrackingNumber
      extLabelUrl

      carrierId
      carrier {
        name
		alias
      }
	  route {
		routeSequence
		routeDistance
		routeDuration
		updatedAt
	  }

      driverId
      driver {
        name
		location {
			latitude
			longitude
		}
      }
	  readyForPickupTime
      deliveryServiceId
      deliveryService {
        id
        type
        name
       orderBy
        pickupBy
        tatMin
        tatMax
        maxDistance
		slackChannel
		postalcode_whitelist
		postalcode_blacklist	
      }

      history {
        items {
          id
          createdTime
          createdAt
          status
          description
          attachment
          signature
          userId
          user {
            name
            image
          }
		  location{
			latitude
			longitude
		  }
        }
      }

      ratings {
        items {
          id
          rating
          nps
          feedback
        }
      }
    }
  }
`;

const updateShipment = /* GraphQL */ `
  mutation UpdateShipment(
    $input: UpdateShipmentInput!
    $condition: ModelShipmentConditionInput
  ) {
    updateShipment(input: $input, condition: $condition) {
      id
      status
      shipTo
	  shipFrom
	  shipperGroupId
	  shipperId
      items
      services
      attachment
	  batch
	  authCode
	  actualPickupTime
	  actualDeliveryTime
      deliveryInstructions
      expectedPickupTime
      expectedDeliveryTime
	  carrierId
	  carrier{
		id 
		name
		alias
	  }
	  createdTime
	  customerId
	  deliveryServiceId
	  distance
      driverId
      driver {
        name
        image
      }
      deliveryService {
        id
        type
        name
        pickupBy
        tatMin
        tatMax
        maxDistance
      }
	  extId
	  weight
	  billing
	  extTrackingNumber
	  number
	  userId
	  outForReturnTime
	  returnedTime
	  updatedAt
	  createdAt
    }
  }
`;

const deliveryServicesByShipperId = /* GraphQL */ `
  query DeliveryServicesByShipperId(
    $shipperId: ID!
	$filter: ModelDeliveryServiceFilterInput
  ) {
    deliveryServicesByShipperId(
      shipperId: $shipperId
	  filter: $filter
    ) {
      items {
        id
        type
        name
        pickupBy
		orderBy
        tatMin
        tatMax
        maxDistance
		slackChannel
		postalcode_whitelist
		postalcode_blacklist
        shipperId
        carrierId
		carrier {
			name
			id
			image
			alias
			active
		}
      }
      nextToken
    }
  }
`;

const createShipmentStatusHistory = /* GraphQL */ `
  mutation CreateShipmentStatusHistory(
    $input: CreateShipmentStatusHistoryInput!
    $condition: ModelShipmentStatusHistoryConditionInput
  ) {
    createShipmentStatusHistory(input: $input, condition: $condition) {
      id
      status
      description
      attachment
      signature
      createdTime
      userId
      user {
        image
        name
      }
    }
  }
`;

const sendSlackAlert = /* GraphQL */ `
  mutation SendSlackAlert($input: SendSlackAlertInput!) {
    sendSlackAlert(input: $input)
  }
`;

const sendCarrierNotificationMutation = /* GraphQL */ `
  query sendCarrierNotification ($shipmentId: ID!) {
    sendShipmentCarrierNotification (shipmentId: $shipmentId)
	sendShipmentCarrierUserNotification (shipmentId: $shipmentId)
  }
`;

const createFedexLabelMutation = /* GraphQL */ `
  mutation createFedexLabel ($id: ID!) {
    createFedexLabel (id: $id)
  }
`;

const cancelFedexLabel = /* GraphQL */ `
  mutation CancelFedexLabel($id: ID!) {
    cancelFedexLabel(id: $id)
  }
`;

const sendShipmentPatientNotification = /* GraphQL */ `
  query SendShipmentPatientNotification($shipmentId: ID!) {
    sendShipmentPatientNotification(shipmentId: $shipmentId)
  }
`;

const onUserLocationUpdate = /* GraphQL */ `
  subscription UserLocationSubscription ($filter: ModelSubscriptionUserLocationFilterInput) {
    onUpdateUserLocation(filter: $filter) {
      	id
	  	latitude
    	longitude
    }
  }
`;

const onShipmentRouteUpdate = /* GraphQL */ `
  subscription ShipmentRouteSubscription ($filter: ModelSubscriptionShipmentRouteFilterInput) {
    onUpdateShipmentRoute(filter: $filter) {
		id
		routeSequence
		routeDuration
		routeDistance
		updatedAt
	}
  }
`;

const createUpsLabelMutation = /* GraphQL */ `
mutation CreateUpsLabel($id: ID!) {
  createUpsLabel(id: $id)
}
`;

const cancelUpsLabelMutation = /* GraphQL */ `
  mutation CancelUpsLabel($id: ID!) {
    cancelUpsLabel(id: $id)
  }
`;

const sendShipmentCarrierUserNotification = /* GraphQL */ `
  query SendShipmentCarrierUserNotification($shipmentId: ID!) {
    sendShipmentCarrierUserNotification(shipmentId: $shipmentId)
  }
`;

const createEndiciaLabelMutation = /* GraphQL */ `
  mutation CreateEndiciaLabel($id: ID!) {
    createEndiciaLabel(id: $id)
  }
`;

const cancelEndiciaLabelMutation = /* GraphQL */ `
mutation CancelEndiciaLabel($id: ID!) {
  cancelEndiciaLabel(id: $id)
}
`;


const updateShipmentBoxMutation = /* GraphQL */ `
	mutation UpdateShipmentBox(
	  $input: UpdateShipmentBoxInput!
	) {
	  updateShipmentBox(input: $input) {
		id
	  }
	}
  `;

const cancelCxtLabelMutation = /* GraphQL */ `
  mutation CancelCxtLabel($id: ID!) {
    cancelCxtLabel(id: $id)
  }
`;


const CarrierDriver = ({ shipment, deliveryServices, carrierlist, id, getShipment, getDeliveryServices, qrCode }) => {
	const [qrModal, OpenQrModal] = useState(false);
	const [courierModal, OpentCourierModal] = useState(false)
	const OpenQrModalFun = () => OpenQrModal(true)

	const [spinner, showSpinner] = useState(false);
	const [carrierId, setCarrierId] = useState(shipment?.carrierId);
	const [serviceList, setServicelist] = useState(deliveryServices)
	const [deliveryServiceId, setDeliveryServiceId] = useState(shipment?.deliveryServiceId)
	const [deliveryService, setDeliveryService] = useState(shipment?.deliveryService)
	const [expectedPickupDeliveryTime, setExpectedPickupDeliveryTime] = useState({
		expectedPickupTime: shipment?.expectedPickupTime,
		expectedDeliveryTime: shipment?.expectedDeliveryTime,
		type: shipment?.deliveryService?.type
	})



	const [weight, setWeight] = useState(shipment?.weight || null)
	const [errors, setErrors] = useState({})

	const enterWeight = localStorage.getItem('enterWeight')

	useEffect(() => {
		setTimeout(() => {
			if (deliveryServices && carrierId && enterWeight && !weight && shipment?.carrier?.name === 'USPS') {
				OpentCourierModal(true)
				localStorage.removeItem('enterWeight')
			}
		}, 1000)
	}, [])

	useEffect(() => {
		let services = deliveryServices?.filter(item => item?.carrier?.id === carrierId)
		setServicelist(services.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' })))
	}, [carrierId, deliveryServices])

	let carrier = carrierlist.filter((item) => item.id === carrierId)

	const getServiceId = async (carrierId) => {
		setCarrierId(carrierId)
		let services = deliveryServices.filter(item => item.carrier.id === carrierId)
		setServicelist(services)
		setDeliveryServiceId('')
	}


	const getPickupDeliveryTime = (deliveryServiceid) => {
		setDeliveryServiceId(deliveryServiceid)
		let deliveryServiceArray = deliveryServices.filter(item => item.id === deliveryServiceid)
		let deliveryService = deliveryServiceArray[0];
		setDeliveryService(deliveryService)

		let expectedPickupTime = null
		let expectedDeliveryTime = null


		if (deliveryService?.type === 'TODAY') {
			expectedPickupTime = moment.unix(shipment?.createdTime).add(1, 'hours')
			expectedDeliveryTime = moment.unix(shipment?.createdTime).add(deliveryService.tatMax + 1, 'hours')
		} else if (deliveryService?.type === 'TOMORROW') {
			const dateString = moment.unix(shipment?.createdTime).add(1, 'days').format('YYYY-MM-DD')
			const expectedPickupString = `${dateString} ${deliveryService?.pickupBy}`
			expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
			expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(deliveryService?.tatMax, 'hours')
		} else if (deliveryService?.type === 'MONDAY') {
			const dateString = moment.unix(shipment?.createdTime).add(8 - moment().isoWeekday(), 'days').format('YYYY-MM-DD')
			const expectedPickupString = `${dateString} ${deliveryService?.pickupBy}`
			expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
			expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(deliveryService?.tatMax, 'hours')
		}

		setExpectedPickupDeliveryTime((prev) => ({
			...prev,
			expectedPickupTime: expectedPickupTime ? expectedPickupTime?.unix() : null,
			expectedDeliveryTime: expectedDeliveryTime ? expectedDeliveryTime?.unix() : null,
			type: deliveryService?.type
		}));
	}

	const handlePickupDateChange = async (selectedDates, dateStr) => {
		const expectedPickupString = `${dateStr} ${shipment?.deliveryService?.pickupBy}`
		const expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
		const expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(shipment?.deliveryService?.tatMax, 'hours')

		setExpectedPickupDeliveryTime((prev) => ({
			...prev,
			expectedPickupTime: expectedPickupTime.unix(),
			expectedDeliveryTime: expectedDeliveryTime.unix()
		}));
	}



	const isFormValid = async () => {
		let error = {};
		if (!deliveryServiceId) error.service = 'Please select a Service';
		if (!carrierId) error.carrier = 'Please select a Courier';
		if ((carrier[0]?.name?.toLowerCase() === 'fedex' || carrier[0]?.name?.toLowerCase() === 'usps' || carrier[0]?.name?.toLowerCase() === 'ups')) {
			if (!weight) error.weight = 'Weight is required'
			if (weight < 0) error.weight = 'Please enter a valid weight'
			if (weight?.toString()?.trim()?.startsWith('.')) error.weight = 'Please enter a valid weight'
		}

		// check for distance & whitelist
		if (!['usps', 'fedex', 'ups'].includes(shipment?.carrier?.alias.toLowerCase())) {

			// check for Blocked zipcode if address is blocked
			if (deliveryService?.postalcode_blacklist !== null && deliveryService?.postalcode_blacklist?.includes(shipment?.shipTo?.address?.postalCode?.split('-')[0])) {
				error.service = 'Service is not available at this address!'
			} else if (deliveryService?.maxDistance && (deliveryService?.postalcode_whitelist?.length > 0) && (shipment?.distance > deliveryService?.maxDistance) && !deliveryService?.postalcode_whitelist?.includes(shipment?.shipTo?.address?.postalCode?.split('-')[0])) {
				error.service = `To use this service, the delivery address must be within ${deliveryService?.maxDistance} mi.`
			} else if (!deliveryService?.maxDistance && (deliveryService?.postalcode_whitelist?.length > 0) && !deliveryService?.postalcode_whitelist?.includes(shipment?.shipTo?.address?.postalCode?.split('-')[0])) {
				error.service = 'Service is not available at this address!'
			} else if ((!deliveryService?.postalcode_whitelist?.length > 0) && deliveryService?.maxDistance && (shipment?.distance > deliveryService?.maxDistance)) {
				error.service = `To use this service, the delivery address must be within ${deliveryService?.maxDistance} mi.`
			}
		}

		if (shipment?.carrier?.alias.toLowerCase() === 'usps' && shipment?.weight) {
			if (shipment?.shipper?.shipperGroup?.alias.toLowerCase() === "metrohealth") {
				if (weight >= 1.0 && deliveryService?.value.toLowerCase() !== 'priority') {
					error.weight = 'Service type not supported for the given weight, please select priority mail';
				}
			} else if (weight >= 1.0 && deliveryService?.value.toLowerCase() === 'first') {
				error.weight = 'Service type not supported for the given weight';
			}
		}
		let selectedCarrier = carrierlist?.find((item) => item.id === carrierId)
		if (shipment?.shipFrom?.alias?.toLowerCase() === "aurora mail order pharmacy" && ["WI", "IL"].includes(shipment?.shipTo?.address?.state)) {
			if (selectedCarrier?.name?.includes("WI")) {
				let distance = await getDistanceFromHere(shipment?.shipTo?.address?.location, shipment?.shipFrom?.address?.location);
				if (distance > 55) {
					error.carrier = 'Distance is more then 55 mi, please select another Courier';
				}
			} else if (selectedCarrier?.name?.includes("IL")) {
				const chicagoLocation = { latitude: 42.047586035877956, longitude: -87.98072053317183 }
				const chicagoDistance = await getDistanceFromHere(chicagoLocation, shipment?.shipTo?.address?.location);
				if (chicagoDistance > 55) {
					error.carrier = 'Distance is more then 55 mi, please select another Courier';
				}
			}
		}

		if (expectedPickupDeliveryTime.type === 'FUTURE' && (!expectedPickupDeliveryTime.expectedDeliveryTime && !expectedPickupDeliveryTime.expectedPickupTime)) error.date = 'Please select a future date';
		setErrors(error);
		return Object.keys(error).length === 0
	}

	const updateCarrierDetails = async () => {
		if (!await isFormValid()) return;
		showSpinner(true);

		let input = {
			id: id,
			carrierId,
			deliveryServiceId,
			expectedPickupTime: expectedPickupDeliveryTime?.expectedPickupTime,
			expectedDeliveryTime: expectedPickupDeliveryTime?.expectedDeliveryTime
		}
		if (carrier[0]?.name?.toLowerCase() === 'fedex' || 'usps' || 'ups') {
			input.weight = weight;
		}

		if (enterWeight) {
			localStorage.removeItem('enterWeight')
		}


		API.graphql({
			query: updateShipment, variables: { input }
		}).then(async () => {
			let task = []

			//#region billing
			await processBilling(id)
			//#region generate label
			if (carrier[0]?.name?.toLowerCase() === 'fedex') {
				task.push(API.graphql({ query: createFedexLabelMutation, variables: { id: id } }));
			}
			if (carrier[0]?.name?.toLowerCase() === 'usps') {
				task.push(API.graphql({ query: createEndiciaLabelMutation, variables: { id: id } }));
			}
			if (carrier[0]?.name?.toLowerCase() === 'ups') {
				task.push(API.graphql({ query: createUpsLabelMutation, variables: { id: id } }));
			}
			//#endregion 

			//#region switch from fedex/usps/ups to phox carrier
			if ((shipment?.carrier?.name?.toLowerCase() === 'fedex' || shipment?.carrier?.name?.toLowerCase() === 'usps' || shipment?.carrier?.name?.toLowerCase() === 'ups')) {
				if ((carrier[0]?.name?.toLowerCase() !== 'fedex' || carrier[0]?.name?.toLowerCase() !== 'usps' || carrier[0]?.name?.toLowerCase() !== 'ups')) {
					shipment?.carrier?.name?.toLowerCase() === 'ups' && task.push(API.graphql({ query: cancelUpsLabelMutation, variables: { id } }))
					shipment?.carrier?.name?.toLowerCase() === 'usps' && task.push(API.graphql({ query: cancelEndiciaLabelMutation, variables: { id } }))
					shipment?.carrier?.name?.toLowerCase() === 'fedex' && task.push(API.graphql({ query: cancelFedexLabel, variables: { id } }))
					shipment?.carrier?.alias?.toLowerCase() === 'clockwork' && task.push(API.graphql({ query: cancelCxtLabelMutation, variables: { id } }))
				}
			}
			//#endregion 

			task.push(API.graphql({ query: sendCarrierNotificationMutation, variables: { shipmentId: id } }))

			try {
				await Promise.all(task);
				toast.success('Shipment has been updated')
				getShipment();
				OpentCourierModal(false);
				if (shipment?.carrier?.name?.toLowerCase() === 'usps' && shipment?.extLabelUrl) {
					printJS(shipment?.extLabelUrl)
				}
				showSpinner(false);
			} catch (error) {
				handleApiError(error);
				OpentCourierModal(false);
				getShipment();
				getDeliveryServices(shipment?.shipperId, shipment?.isGreenPhox)
				setCarrierId(shipment?.carrierId)
				setExpectedPickupDeliveryTime({
					expectedPickupTime: shipment?.expectedPickupTime,
					expectedDeliveryTime: shipment?.expectedDeliveryTime,
					type: shipment?.deliveryService?.type
				})
				showSpinner(false);

			}
		}).catch((error) => {
			handleApiError(error)
			OpentCourierModal(false);
			showSpinner(false);
			getDeliveryServices(shipment?.shipperId, shipment?.isGreenPhox)
			setCarrierId(shipment?.carrierId)
			setExpectedPickupDeliveryTime({
				expectedPickupTime: shipment?.expectedPickupTime,
				expectedDeliveryTime: shipment?.expectedDeliveryTime,
				type: shipment?.deliveryService?.type
			})
		})
	}

	let cardButtons = [{ buttonName: <img src="/img/qricon.png" style={{ height: '20px' }} />, clickfun: OpenQrModalFun }];
	(['OPEN']?.includes(shipment?.status) && carrierlist?.length > 0) && cardButtons.push({ buttonName: 'Edit', clickfun: () => OpentCourierModal(true) })

	const inputRef = useRef(null);
	useEffect(() => {
		if (['fedex', 'usps', 'ups'].includes(carrier[0]?.name.toLowerCase())) {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}
	}, [carrier]);

	return (
		<>
			<MultiButtonCard title="Courier Details" buttons={cardButtons} >
				<div>
					<ListGroupItem name="Courier Name" value={shipment?.carrier ? shipment?.carrier?.name : "-"} />
					{
						!['usps', 'ups', 'fedex'].includes(shipment?.carrier?.name.toLowerCase()) &&
						<>
							<hr />
							<ListGroupItem name="Driver Name" value={shipment?.driver?.name || "Unassigned"} />
						</>
					}
					{shipment?.status !== ('OPEN' || 'OUT_FOR_RETURN') &&
						<>
							<hr />
							<ShipmentStats shipment={shipment} id={id} />
						</>
					}
				</div>
			</MultiButtonCard >

			<Modal
				show={qrModal}
				aria-labelledby="contained-modal-title-vcenter"
				onHide={() => OpenQrModal(false)} >
				<Modal.Header>
					<Modal.Title className="modal-title">
						QR Code
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{qrCode && (
						<div className="text-center">
							<QRCode.QRCodeSVG value={qrCode} size={150} />
						</div>
					)}
				</Modal.Body>
				<Modal.Footer className="display-flex-start">
					<button onClick={() => { OpenQrModal(false) }} className="btn btn-outline-light text-muted">
						Close
					</button>
				</Modal.Footer>
			</Modal >


			<Modal show={courierModal}>
				<Modal.Header>
					<Modal.Title className='modal-title'>Update Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<FormLabel required={true}>Courier</FormLabel>
						<Form.Select className='phox-form-select' value={carrierId} onChange={(e) => getServiceId(e.target.value)} isInvalid={!!errors?.carrier}>
							<option value=''>Select Courier</option>
							{
								carrierlist?.map((carrier, index) => <option key={index} value={carrier.id}>{carrier?.name}</option>)
							}
						</Form.Select>
						<Form.Control.Feedback key={errors?.carrier} type='invalid'>{errors?.carrier}</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className='mb-3' >
						<FormLabel required>Delivery Service </FormLabel>
						<Form.Select value={deliveryServiceId}
							key={deliveryServiceId}
							onChange={(e) => getPickupDeliveryTime(e.target.value)}
							isInvalid={!!errors?.service}>
							<option value='' >Select Delivery Service</option>
							{
								serviceList.map((item, index) => !item.disabled && <option key={index} value={item?.id}>{item?.name}</option>)
							}
						</Form.Select>
						<FormError error={errors?.service} />
					</Form.Group>


					{['fedex', 'usps', 'ups'].includes(carrier[0]?.alias.toLowerCase()) &&
						<Form.Group className='mb-3' >
							<FormLabel required>Weight </FormLabel>
							<Form.Control type='number' placeholder='e.g. 2' defaultValue={shipment?.weight || ''} ref={inputRef} onChange={(e) => setWeight(e.target.value)} onWheel={(e) => e.target.blur()} />
							<FormError error={errors?.weight} />
							<Form.Control.Feedback type='invalid'>{errors?.weight}</Form.Control.Feedback>
						</Form.Group>
					}

					{
						expectedPickupDeliveryTime.type === 'FUTURE' && <div>
							<hr />
							<FormLabel required>Deliver On </FormLabel>
							<Form.Group className='mb-3 row'>
								<Flatpickr className='form-control'
									onChange={handlePickupDateChange}
									value={moment.unix(expectedPickupDeliveryTime?.expectedPickupTime).toDate()}
									options={{
										altInput: true,
										altFormat: 'F j, Y',
										minDate: moment().toDate(),
										static: true
									}}>
									<input data-input className='form-input' />
								</Flatpickr>
							</Form.Group>
							<FormError error={errors?.date} />
						</div>
					}

					{!['fedex', 'usps', 'ups'].includes(carrier[0]?.alias.toLowerCase()) &&

						<>
							<Form.Group className='mb-3'>
								<FormLabel required={true}>Estimated Pickup By</FormLabel>
								<div className='border p-3  rounded'> {toLocalDateTime(expectedPickupDeliveryTime?.expectedPickupTime, shipment?.shipFrom?.timezone?.id)}   </div>
							</Form.Group>

							<Form.Group className='mb-3'>
								<FormLabel required={true}>Estimated Delivery By</FormLabel>
								<div className='border p-3  rounded'>{toLocalDateTime(expectedPickupDeliveryTime?.expectedDeliveryTime, shipment?.shipFrom?.timezone?.id)}   </div>
							</Form.Group>
						</>
					}
				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => updateCarrierDetails()}>Update</button>
						<button className='btn btn-link text-muted'
							onClick={() => {
								OpentCourierModal(false);
								setErrors();
								getDeliveryServices(shipment?.shipperId, shipment?.isGreenPhox)
								setCarrierId(shipment?.carrierId)
								setExpectedPickupDeliveryTime({
									expectedPickupTime: shipment?.expectedPickupTime,
									expectedDeliveryTime: shipment?.expectedDeliveryTime,
									type: shipment?.deliveryService?.type
								})
								localStorage.removeItem('enterWeight')
							}} >Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal>
		</>
	);
};

const ShipmentStats = ({ shipment, id }) => {
	const [ShipmentRoute, setShipmentRoute] = useState(shipment?.route);
	const [timeRemaining, setTimeRemaining] = useState("");

	useEffect(() => {
		let diffInMins = moment().diff(moment(shipment?.route?.updatedAt), "minutes")
		let timeDiff = (shipment?.route?.routeDuration - diffInMins) * 60
		setTimeRemaining(timeDiff)
	}, [shipment?.route?.updatedAt])


	useEffect(() => {
		const shipmentRouteSubscription = API.graphql({ query: onShipmentRouteUpdate, variables: { filter: { id: { eq: id } } } }).subscribe({
			next: (response) => {
				const route = response.value.data.onUpdateShipmentRoute;
				setShipmentRoute({ ...shipment, route: route })
				let diffInMins = moment().diff(moment(shipment?.route?.updatedAt), "minutes")
				setTimeRemaining((shipment?.route?.routeDuration - diffInMins) * 60)
			},
			error: (error) => console.error('Subscription error:', error),
		});

		return () => {
			shipmentRouteSubscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeRemaining(prevTime => prevTime - 1);
		}, 1000);
	}, []);


	let liveHours = Math.floor(timeRemaining / 3600);
	let liveMinutes = Math.floor((timeRemaining % 3600) / 60);
	let liveETA = liveHours > 0 ? `${liveHours} hr ${liveMinutes} min` : `${liveMinutes} min`;

	let deliveryStatus;

	if (ShipmentRoute?.routeSequence > 1) {
		deliveryStatus = `The courier is ${ShipmentRoute?.routeSequence} stops and ${liveETA} away`
	} else if (ShipmentRoute?.routeSequence === 1) {
		deliveryStatus = `Courier should arrive in about  ${liveETA}`
	} else if (shipment?.route?.routeSequence === 1 && liveMinutes <= 0) {
		deliveryStatus = `Courier will arrive shortly. For more information contact us at (844) 688-7469`
	}

	let pickuptime;
	const readyForPickupItem = shipment?.history?.find(item => item?.status === "READY_FOR_PICKUP");
	if (readyForPickupItem) {
		pickuptime = moment(readyForPickupItem?.createdTime);
	}

	const tat = (shipment?.status === 'DELIVERED' && shipment?.actualDeliveryTime) ? moment.unix(shipment?.actualDeliveryTime).diff(moment.unix(pickuptime), 'minutes') : '-';
	const hrs = tat && Math.floor(tat / 60);
	const min = tat && tat % 60;
	const tatData = hrs > 0 ? `${hrs} hr, ${min} ${min === 1 ? `min` : `min(s)`}` : `${min} ${min === 1 ? `min` : `min(s)`}`;

	return (
		<>
			{shipment?.status === "READY_FOR_PICKUP" && (
				<ListGroupItem name="Estimated Pickup By"
					value={`${toLocalDateTime(
						shipment?.expectedPickupTime,
						shipment?.shipFrom?.timezone?.id
					)}`}
				/>
			)}

			{shipment?.status === "IN_TRANSIT" && (
				<ListGroupItem
					name="Estimated Delivery By"
					value={`${toLocalDateTime(
						shipment?.expectedDeliveryTime,
						shipment?.shipFrom?.timezone?.id
					)}`}
				/>
			)}

			{shipment?.status === "OUT_FOR_DELIVERY" && (

				<ListGroupItem
					name="Estimated Delivery By"
					value={`${deliveryStatus || toLocalDateTime(
						shipment?.expectedDeliveryTime,
						shipment?.shipFrom?.timezone?.id
					)}`}
				/>
			)}

			{shipment?.status === "DELIVERED" && (
				<>
					<ListGroupItem
						name="Delivered At"
						value={toLocalDateTime(
							shipment?.actualDeliveryTime,
							shipment?.shipFrom?.timezone?.id,
							shipment?.shipFrom?.timezone?.alias
						)}
					/>
					<hr />
					<ListGroupItem
						name="Turnaround Time"
						value={(tatData && !isNaN(tat) && tatData)}
					/>
				</>
			)}

			{shipment?.status === "RETURNED" && (
				<ListGroupItem
					name="Returned At"
					value={toLocalDateTime(
						shipment?.returnedTime,
						shipment?.shipFrom?.timezone?.id,
						shipment?.shipFrom?.timezone?.alias
					)}
				/>
			)}
		</>
	);
};


const ShipmentDetailMap = ({ shipment, viewState }) => {
	const [mapStyle, setMapStyle] = useState(awsconfig.geo.amazon_location_service.maps.default);
	const [driver, setDriver] = useState(shipment.driver)

	useEffect(() => {
		if (shipment.driverId && shipment.status === 'OUT_FOR_DELIVERY') {
			API.graphql({ query: onUserLocationUpdate, variables: { filter: { id: { eq: shipment.driverId } } } }).subscribe({
				next: (response) => {

					let location = {
						latitude: response?.value?.data?.onUpdateUserLocation.latitude,
						longitude: response?.value?.data?.onUpdateUserLocation.longitude
					}
					setDriver((prev) => ({ ...prev, location }))
				},
				error: (error) => console.error('onUserLocationUpdate', error)
			});

		}

	}, [])

	let lineColor = mapStyle.includes('satellite') ? '#405AFF' : '#6e84a3'

	return (
		<div className='mt-n2 bg-light' style={{ width: '100%', height: '500px' }}>
			<MapView initialViewState={viewState} style={{ width: '100%', height: '500px' }} mapStyle={mapStyle} >
				<ShipperMarker shipper={shipment.shipFrom} />
				<ShipmentMarker shipment={shipment} shipmentDetailsLatLong={true} />
				<ActualDeliveredMarker shipment={shipment} />
				{shipment.status === 'OUT_FOR_DELIVERY' && <DriverMarker driver={driver} />}
				<ReturnedMarker shipment={shipment} />
				<OutForReturnMarker shipment={shipment} />

				<div className="d-flex justify-content-end me-3" style={{ zIndex: 3, position: 'absolute', bottom: 30, right: 10 }} >
					<MapToggleButton mapStyle={mapStyle} setMapStyle={setMapStyle} />
				</div>
			</MapView>
		</div>
	)
}



const ShipmentDetail = () => {

	const myShipper = useSelector((state) => state.slice.SHIPPER);
	const myUser = useSelector((state) => state.slice.USER);
	const [selectedLogType, setSelectedLogType] = useState('');
	const [selectedShipment, setSelectedShipment] = useState({});
	const [drawerOpen, setDrawerOpen] = useState(false);

	const { id } = useParams();
	const { state } = useLocation();
	const mapRef = useRef();
	const navigate = useNavigate();

	const [spinner, showSpinner] = useState(true);
	const [shipment, setShipment] = useState([]);
	const [qrCode, setQrCode] = useState();
	const [deliveryServices, setDeliveryServices] = useState([]);
	const [carrierlist, setCarrierlist] = useState([]);
	const [multi_Dimensional_Shipment_Item, setMulti_Dimensional_Shipment_Item] = useState(false)
	const [downloadPodPdf, setDownloadPodPdf] = useState(false);
	const [tempShipment, setTemShipment] = useState({});

	const [viewState, setViewState] = useState({
		latitude: 37.7061332,
		longitude: -101.4802194,
		zoom: 4,
	});

	useEffect(() => {
		getShipment();
	}, []);

	useEffect(() => {
		if (shipment?.number) {
			document.title = `Shipment #${shipment?.number} ${PAGE_TITLE}`;
			ReactGA.send({
				hitType: "pageview",
				page: `/shipment/${id}`,
			})
		}
	}, [shipment?.number]);

	useEffect(
		() =>
			mapRef.current?.flyTo({
				center: [viewState.longitude, viewState.latitude],
				zoom: 10,
			}),
		[viewState]
	);

	useEffect(() => {
		setTimeout(() => {
			let printBtn = document.getElementById("printBtn");
			if (printBtn && qrCode && state?.print) printBtn.click();
		}, 1000);
	}, [qrCode]);

	useEffect(() => {
		setTimeout(() => {
			let fedexUspsPrintBtn = document.getElementById("fedexUspsPrintBtn");
			if (fedexUspsPrintBtn && state?.print && shipment?.carrier?.name) {
				fedexUspsPrintBtn.click();
			}
		}, 1000);
	}, [shipment?.extLabelUrl]);


	const updateStatus = () => {
		showSpinner(true)
		let inputShipment = { id, status: 'READY_FOR_PICKUP', readyForPickupTime: moment().unix(), }
		const currentTimestamp = moment().unix();
		let inputShipmentHistory = {
			id: uniqid(),
			shipmentId: shipment.id,
			userId: myUser.id,
			status: 'READY_FOR_PICKUP',
			createdTime: currentTimestamp,
			description: 'Shipment label has been printed'
		}

		let slackAlert = '';
		if (shipment?.deliveryService?.slackChannel?.trim()) {
			slackAlert = shipment?.deliveryService?.slackChannel?.trim();
		} else {
			slackAlert = (shipment?.deliveryService?.type === 'TODAY' && shipment?.deliveryService?.tatMax <= 4) ? 'critical-alerts' : 'alerts'
		}

		const slackInput = {
			channel: slackAlert,
			message: `Shipment ${shipment?.number} (${shipment?.deliveryService?.name}) is READY_FOR_PICKUP`,
			user: shipment?.shipFrom?.name
		}

		if (shipment.deliveryService.type === 'TODAY' && shipment.status === 'OPEN') {
			API.graphql({ query: sendShipmentCarrierUserNotification, variables: { shipmentId: shipment.id } })
		}

		Promise.all([
			API.graphql({ query: updateShipment, variables: { input: inputShipment } }),
			API.graphql({ query: createShipmentStatusHistory, variables: { input: inputShipmentHistory } }),
			API.graphql({ query: sendSlackAlert, variables: { input: slackInput } }),
		]).then(async () => {
			await API.graphql({ query: sendShipmentPatientNotification, variables: { shipmentId: shipment.id } })
			await processBilling(id);
			getShipment();
			toast.success(`Status has been updated to ${ShipmentStatus['READY_FOR_PICKUP']}`);
			setTimeout(() => {
				const parts = window.location.href.split('/');
				const shipmentIndex = parts.findIndex(part => part.startsWith('shipment'));

				if (shipmentIndex !== -1 && parts.length > shipmentIndex + 1 && parts[shipmentIndex + 1] !== 'create') {
					const shipmentId = parts[shipmentIndex + 1];
					if (shipmentId.length > 0) {
						navigate('/shipment');
					} else {
						console.error("No shipment ID found, redirection failed.");
					}
				} else {
					console.error("URL does not contain valid shipment ID, redirection failed.");
				}
			}, 15000)
		}).catch((error) => {
			handleApiError(error)
		}).finally(() => showSpinner(false));

		return false;
	}


	function checkDimension(arr) {
		if (Array.isArray(arr) && arr.some(innerArr => Array.isArray(innerArr))) {
			setMulti_Dimensional_Shipment_Item(true)
		} else if (Array.isArray(arr)) {
			setMulti_Dimensional_Shipment_Item(false)
		} else {
			console.error("Not an array");
		}
	}

	const getShipment = () => {
		showSpinner(true);
		API.graphql({ query: getShipmentQuery, variables: { id: id } })
			.then(async (data) => {
				let shipment = data?.data?.getShipment;
				if (shipment) {
					if (shipment.shipFrom)
						shipment.shipFrom = JSON.parse(shipment.shipFrom);
					if (shipment.shipTo) shipment.shipTo = JSON.parse(shipment.shipTo);
					if (shipment.items) shipment.items = JSON.parse(shipment.items);
					if (shipment.history?.items?.length > 0)
						shipment.history = shipment.history?.items?.sort(
							(a, b) => new Date(b.createdTime) - new Date(a.createdTime)
						);
					else shipment.history = [];

					if (shipment?.status === "DELIVERED") {
						setViewState({
							latitude: shipment?.shipTo?.address?.location?.latitude,
							longitude: shipment?.shipTo?.address?.location?.longitude,
							zoom: 18,
						})
					} else {
						setViewState({
							latitude: shipment?.shipFrom?.address?.location?.latitude,
							longitude: shipment?.shipFrom?.address?.location?.longitude,
							zoom: 12,
						});
					}

					setShipment(shipment);
					setTemShipment(shipment);
					checkDimension(shipment.items)

					getDeliveryServices(shipment.shipperId, shipment?.isGreenPhox);
					let qrCode = `${shipment?.id || ""}`;
					setQrCode(qrCode);

					if (shipment.driverId && shipment.status === 'OUT_FOR_DELIVERY') {
						API.graphql({ query: onUserLocationUpdate, variables: { filter: { id: { eq: shipment.driverId } } } }).subscribe({
							next: (response) => {
								const location = response?.value?.data?.onUpdateUserLocation
								setShipment((prev) => ({ ...prev, driver: { ...prev.driver, location: location } }));
							},
							error: (error) => console.error('onUserLocationUpdate', error)
						});
					}
				} else {
					console.error("shipment not found");
				}
			})
			.catch((error) => handleApiError(error))
			.finally(() => showSpinner(false));
	};

	const getDeliveryServices = async (shipperId, isGreenPhox) => {
		API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId, filter: { active: { eq: true } } } }).then(({ data }) => {
			let items = data?.deliveryServicesByShipperId?.items;
			let carriers = [];

			if (items.length > 0) {
				items.forEach((item) => {
					if (item?.type === "TODAY" && item?.tatMax > 4 && item?.pickupBy < moment().format("HH:mm")) {
						item.disabled = true;
					}
				});
				setDeliveryServices(items)
				// set all carriers
				if (isGreenPhox) {
					items.forEach((item) => {
						if (!['usps', 'ups', 'fedex'].includes(item.carrier.name.toLowerCase())) {
							if (carriers.findIndex(x => x.id === item?.carrier?.id) < 0 && item?.carrier?.active !== false)
								carriers.push(item.carrier)
						}
					});
				} else {
					items.forEach((item) => {
						if (carriers.findIndex(x => x.id === item?.carrier?.id) < 0 && item?.carrier?.active !== false)
							carriers.push(item.carrier)
					});
				}

				setCarrierlist(carriers.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' })))
			}
		}).catch(error => handleApiError(error))
	};

	useEffect(() => {
		if (shipment && deliveryServices) {
			defaultFirstClassmail()
		}
	}, [tempShipment, deliveryServices])

	const defaultFirstClassmail = () => {
		let firstClassmail = deliveryServices?.find((item) => item?.name?.toLowerCase() === "first-class mail")
		if (shipment?.shipFrom?.alias?.toLowerCase() === "aurora mail order pharmacy" && !shipment?.extId && shipment?.carrier?.name?.toLowerCase() === 'usps' && firstClassmail) {
			setShipment((prev) => ({ ...prev, deliveryServiceId: firstClassmail?.id, deliveryService: firstClassmail }))
		}
	}


	//#region scan
	const handleScan = async (data) => {
		let loading = toast.loading("Loading...")
		try {
			let keyword = data;
			if (keyword.startsWith("\\")) {
				keyword = keyword.slice(1, -1)
			}
			if (keyword.indexOf("^") >= 0) keyword = data.split("^")[0];
			if (keyword.indexOf("RXR") >= 0) keyword = keyword.replace("RXR", "");

			const apiName = 'api';
			const path = `/search/shipment?size=${PAGE_SIZE}`;
			let init = {
				body: {
					sort: [{ _score: { order: 'desc' } }],
					query: {
						bool: {
							must: [{ match: { shipperId: myShipper.shipper.id } }]
						}
					}
				}
			};
			init.body.query.bool.must.push({ "bool": { "should": [{ "match": { "status": "OPEN" } }, { "match": { "status": "READY_FOR_PICKUP" } }] } })
			if (data.split("^").length >= 6) {
				data = data.split("^")[0]
				init.body.query.bool["filter"] = { "terms": { "shipTo.name.keyword": [data] } }
			} else { init.body.query.bool["filter"] = { "terms": { "items.number.keyword": [keyword] } } }

			const { hits } = await API.post(apiName, path, init);
			const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
			if (sourceData.length === 0) return toast.error("No data found !")
			sourceData[0]?.id && window.open(`/shipment/${sourceData[0]?.id}`, "_self");
		} catch (error) {
			toast.error("Something went wrong!")
		} finally { toast.dismiss(loading) }

	};

	const handleScanError = (error) => {
		handleApiError(error)
		// window.location.reload(); // package issue
	};
	//#endregion

	//#region Components

	const ShipmentHeader = () => {
		const [spinner, showSpinner] = useState(false);

		const [shipToModal, showShipToModal] = useState(false);

		const [errors, setErrors] = useState();
		const [shipTo, setShipTo] = useState(shipment?.shipTo);
		const [pdfButton, showPdfButton] = useState(true)
		const [distance, setDistance] = useState(shipment?.distance);

		useEffect(() => {
			if (!shipTo?.firstName) {
				let shiptoaddress = shipTo
				shiptoaddress.firstName = shipTo?.name
				setShipTo(shiptoaddress)
			}
		}, [])

		useEffect(() => {
			setTimeout(() => {
				showPdfButton(false)
			}, 2500)
		}, [])

		const handleShipToChange = (e) => setShipTo({ ...shipTo, [e.target.name]: e.target.value });
		const handlePhoneChange = (phone) => setShipTo({ ...shipTo, phone: `+${phone}` });
		const handleAddress1Change = (address1) =>
			setShipTo((prevState) => ({ ...prevState, address: { ...prevState.address, address1: address1, city: "", state: "", postalCode: "" } }));
		const handleAddress2Change = (e) => setShipTo((prevState) => ({ ...prevState, address: { ...prevState.address, address2: e.target.value }, }));

		const handleAddressSelect = async (values, placeId) => {
			const places = await geocodeByPlaceId(placeId);
			const latLong = await getLatLng(places[0]);

			let street_number = "";
			let sub_locality1 = "";
			let sub_locality2 = "";
			let sub_locality3 = "";
			let route = "";
			let city = "";
			let state = "";
			let postalCode = "";

			let location = {};
			let timezone = {};
			if (latLong) {
				location.latitude = latLong.lat;
				location.longitude = latLong.lng;
				timezone = await getLocationTimezone(location);
			}

			places &&
				places.length > 0 &&
				places[0].address_components.forEach((address) => {
					if (address.types.includes("street_number"))
						street_number = address.short_name;
					if (address.types.includes("sublocality_level_3"))
						sub_locality1 = address.short_name;
					if (address.types.includes("sublocality_level_2"))
						sub_locality2 = address.short_name;
					if (address.types.includes("sublocality_level_1"))
						sub_locality3 = address.short_name;
					if (address.types.includes("route")) route = address.short_name;
					if (address.types.includes("locality")) city = address.short_name;
					if (address.types.includes("administrative_area_level_1"))
						state = address.short_name;
					if (address.types.includes("postal_code"))
						postalCode = address.long_name;
				});

			const address = {
				address1: street_number
					? `${street_number} ${route}`
					: `${sub_locality1} ${sub_locality2} ${sub_locality3} ${route}`,
				postalCode,
				city,
				state,
				location,
			}
			const distance = await getDistance(shipment.shipFrom.address.location, address.location);
			setDistance(distance)
			setShipTo({
				...shipTo,
				address: address,
				timezone: timezone,
			});
		};

		const isFormValid = async () => {
			const error = {};
			if (!shipTo?.firstName?.trim()) error.first_name = "First Name is required";
			if (!shipTo?.address?.address1?.trim())
				error.address1 = "Address 1 is required";
			if (!shipTo?.address?.city?.trim())
				error.city = "City is required";
			if (!shipTo?.address?.state?.trim())
				error.state = "State is required";
			if (!shipTo?.address?.postalCode?.trim())
				error.postalCode = "Postal Code is required";
			if (!isValidPhone(shipTo?.phone?.trim()))
				error.phone = "Please enter a valid phone number";
			if (!isValidEmail(shipTo?.email?.trim()))
				error["email"] = "Please enter a valid email";

			// check for distance & whitelist
			if (!['usps', 'fedex', 'ups'].includes(shipment?.carrier?.alias.toLowerCase())) {
				let whitelist = shipment?.deliveryService?.postalcode_whitelist
				let blacklist = shipment?.deliveryService?.postalcode_blacklist
				let maxDistance = shipment?.deliveryService?.maxDistance
				let postalCode = shipTo?.address?.postalCode?.split('-')[0]

				if (isPOBoxAddress(shipTo?.address?.address1)) {
					error.address1 = 'The selected carrier does not support delivery to this location!'
				}

				if (blacklist?.length !== 0 && blacklist?.includes(postalCode)) {
					error.address1 = 'Service is not available at this address!'
				} else if (maxDistance && (whitelist?.length > 0) && (distance > maxDistance) && !whitelist?.includes(postalCode)) {
					error.address1 = `Delivery location is ${distance} mi away, it should be within ${maxDistance} mi.`
				} else if (!maxDistance && (whitelist?.length > 0) && !whitelist?.includes(postalCode)) {
					error.address1 = 'Service is not available at this address!'
				} else if ((!whitelist?.length > 0) && maxDistance && (distance > maxDistance)) {
					error.address1 = `Delivery location is ${distance} mi away, it should be within ${maxDistance} mi.`
				}
			}

			// 55 mi logic 
			if (shipment?.shipper?.alias?.toLowerCase() === "aurora mail order pharmacy" && ["WI", "IL"].includes(shipment?.shipTo?.address?.state)) {
				if (shipment?.carrier?.name.includes('WI')) {
					let distance = await getDistanceFromHere(shipment?.shipTo?.address?.location, shipment?.shipFrom?.address?.location);
					if (distance > 55) {
						error.address1 = "Distance is more then 55 mi, please select another Courier."
						return false
					}
				} else if (shipment?.carrier?.name.includes('IL')) {
					const chicagoLocation = { latitude: 42.047586035877956, longitude: -87.98072053317183 }
					const chicagoDistance = await getDistanceFromHere(chicagoLocation, shipment?.shipTo?.address?.location);
					if (chicagoDistance > 55) {
						error.address1 = "Distance is more then 55 mi, please select another Courier."
						return false
					}
				}
			}
			setErrors(error);
			return Object.keys(error).length === 0;
		};

		const updateShipTo = async () => {
			let error = await isFormValid();
			if (error) {
				showSpinner(true);
				shipTo.name = (shipTo.firstName && shipTo.lastName) ? shipTo.firstName + " " + shipTo.lastName : shipTo.firstName ? shipTo.firstName : shipTo?.name
				const input = { id, shipTo: JSON.stringify(shipTo), distance };
				API.graphql({ query: updateShipment, variables: { input: input } })
					.then(async () => {
						await processBilling(id);
						toast.success("Ship To address has been updated");
						getShipment();
						showShipToModal(false);
					}).catch((error) => handleApiError(error))
					.finally(() => showSpinner(false));
			}
		};

		const generateFedexLabel = () => {
			if (shipment?.carrier?.name === "FedEx" && shipment?.weight) {
				showSpinner(true)
				API.graphql({ query: createFedexLabelMutation, variables: { id: id } })
					.then((response) => {
						toast.success('Shipment Label Generated successfully')
						getShipment();
					}).catch((error) => {
						handleApiError(error)
					}).finally(() => showSpinner(false));
			} else {
				toast.error('Package weight is required')
			}
		}
		const generateUpsLabel = () => {
			if (shipment?.carrier?.name.toLowerCase() === "ups") {
				showSpinner(true)
				API.graphql({ query: createUpsLabelMutation, variables: { id: id } })
					.then((response) => {
						toast.success('Shipment Label Generated successfully')
						getShipment();
					}).catch((error) => {
						handleApiError(error)
					}).finally(() => showSpinner(false));
			}
		}
		const generateUspsLabel = () => {
			if (shipment?.carrier?.name?.toLowerCase() === "usps" && shipment?.weight) {
				showSpinner(true)
				API.graphql({ query: createEndiciaLabelMutation, variables: { id: id } })
					.then((response) => {
						let data = JSON.parse(response.data.createEndiciaLabel)
						if (data.statusCode === 400) {
							toast.error(data.body)
						} else {
							toast.success('Shipment Label Generated successfully')
							getShipment();
						}
					}).catch((error) => {
						handleApiError(error)
					}).finally(() => showSpinner(false));
			} else {
				toast.error('Package weight is required')
			}
		}

		return (
			<div>
				<nav className="header mb-0" >
					<Container fluid>
						<div className="header-body">
							<Row className="align-items-center">
								<Col className="col-auto p-0">
									{(shipment?.status === "READY_FOR_PICKUP" || shipment?.status === "OPEN") &&
										(isOwner(myShipper?.role) || isEditor(myShipper?.role)) && (
											<Button className="btn btn-light btn-sm ms-2" onClick={() => showShipToModal(true)}>
												<i className="fe fe-edit-2" />
											</Button>
										)}
								</Col>
								<Col className="d-flex">
									<div>
										<h1 className="mb-1">
											{`#${shipment?.number}`}
										</h1>

										<h2 className="text-muted mb-1">
											{(!isViewer(myShipper?.role) && shipment?.customerId) ?
												<Link to={`/patient/edit/${shipment?.customerId}`}>
													{shipment?.shipTo?.name || "-"}
												</Link> :
												<>
													{shipment?.shipTo?.name || "-"}
												</>
											}
										</h2>
									</div>
									<div className="align-item-centers">
										{shipment?.isGreenPhox && < img src="/img/greenphox.svg" alt="green-phox-icon rounded-circle" className="mx-2" height={'50px'} width={'50px'} />}
									</div>
								</Col>
								<Col>
									<div className="small text-muted">
										<AddressView address={shipment?.shipTo?.address} />
									</div>
								</Col>
								<Col>
									<div className="small text-muted">
										{shipment?.shipTo?.email && (
											<div>
												<i className="fe fe-mail" /> {shipment?.shipTo?.email}
											</div>
										)}
										{shipment?.shipTo?.phone?.length > 10 && (
											<div>
												<i className="fe fe-phone" />{" "}
												{formatNumber(shipment?.shipTo?.phone)}
											</div>
										)}
									</div>
								</Col>

								<Col className="col-auto">
									{!isViewer(myShipper?.role) && ['RETURNED', 'EXCEPTION'].includes(shipment?.status) && <Button className='btn-md me-2' variant="light" size="md" onClick={() => navigate(`/shipment/reorder/${shipment?.id}`)}	>
										<i className="fe fe-repeat" /> Reorder
									</Button>}
									{shipment?.carrier?.name !== "FedEx" && shipment?.carrier?.name !== "USPS" && shipment?.carrier?.name !== "UPS" && (shipment.status === 'OPEN' || shipment.status === 'READY_FOR_PICKUP') &&
										<Button className='btn btn-light btn-md me-2' id='printBtn' onClick={() => { shipment?.number && window.print(); updateStatus() }}>
											<i className='fe fe-printer me-2' /> Print Label
										</Button>
									}
									{/* generate Fedex Label  */}
									{
										(['OPEN', 'READY_FOR_PICKUP'].includes(shipment?.status) && shipment?.carrier?.name === "FedEx" && !shipment?.extTrackingNumber) &&
										<Button className='btn btn-light btn-md me-2' onClick={() => generateFedexLabel()}>
											{spinner ? 'loading...' : 'Generate FedEx Label'}
										</Button>
									}
									{/* generate UPS Label  */}
									{
										(['OPEN', 'READY_FOR_PICKUP'].includes(shipment?.status) && shipment?.carrier?.name.toLowerCase() === "ups" && !shipment?.extTrackingNumber) &&
										<Button className='btn btn-light btn-md me-2' onClick={() => generateUpsLabel()}>
											{spinner ? 'loading...' : 'Generate UPS Label'}
										</Button>
									}
									{/* generate USPS Label  */}
									{
										(['OPEN', 'READY_FOR_PICKUP'].includes(shipment?.status) && shipment?.carrier?.name.toLowerCase() === "usps" && !shipment?.extTrackingNumber) &&
										<Button className='btn btn-light btn-md me-2' onClick={() => generateUspsLabel()}>
											{spinner ? 'loading...' : 'Generate USPS Label'}
										</Button>
									}

									{['OPEN', 'READY_FOR_PICKUP'].includes(shipment?.status) && ((shipment?.carrier?.name === "FedEx" || shipment?.carrier?.name === "USPS" || shipment?.carrier?.name === "UPS") && shipment?.extLabelUrl) && (
										<button className="btn btn-md btn-light me-2" id="fedexUspsPrintBtn" onClick={() => {
											if (shipment?.extLabelUrl?.endsWith('.gif')) {
												let loader = toast.loading("Generating Label...")
												let imgTag = document.createElement("img")
												let div = document.createElement("div")
												imgTag.src = shipment?.extLabelUrl
												imgTag.id = 'printJS-form'
												imgTag.style.height = '100vh !important'
												imgTag.style.scale = 1.22
												imgTag.style.marginLeft = '4rem'
												imgTag.style.marginTop = '2rem'
												div.append(imgTag)
												document.body.appendChild(div)
												const style = document.createElement('style');
												style.innerHTML = `
												  @media print {
													@page {
														width: 6in !important;
														height: 4in !important;
													  }
													  .shipment-label{
														display : none !important; 
													  } 
													  #printJS-form {
														visibility: visible !important;
														width : 90%
													  }
													  html,body{
														height : 99% !important;
													  }
												  }`
													;
												document.head.appendChild(style);
												setTimeout(() => {
													window.print()
													document.head.removeChild(style);
													document.body.removeChild(div)
													toast.dismiss(loader)
												}, 1600)

											} else {
												const userAgent = navigator.userAgent.toLowerCase();
												const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(userAgent);
												if (isSafariBrowser) {
													const printPdfInNewTab = async () => {
														try {
															const response = await fetch(shipment?.extLabelUrl);
															if (!response.ok) {
																throw new Error(`Failed to fetch PDF: ${response.statusText}`);
															}
															const blob = await response.blob();
															const url = URL.createObjectURL(blob);
															const newTab = window.open(url, '_blank');
															if (!newTab) {
																throw new Error('Failed to open new tab. Please check your browser settings.');
															}

															await new Promise((resolve) => newTab.onload = resolve);
															setTimeout(() => {
																newTab.print();
																newTab.close();
															}, 500)

															setTimeout(() => {
																URL.revokeObjectURL(url);
															}, 1000);
														} catch (error) {
															console.error('Error fetching and printing the PDF:', error);
															alert('An error occurred while fetching and printing the PDF.');
														}
													};
													printPdfInNewTab();
												} else {
													printJS(shipment?.extLabelUrl);
												}

											}
											updateStatus();

										}}>
											<i className="fe fe-printer me-2"></i> Print Label
										</button>
									)}

									{
										shipment?.status === "DELIVERED" &&
										<Button className='btn btn-light btn-md me-2  hide-on-print' onClick={() => setDownloadPodPdf(true)} disabled={downloadPodPdf || pdfButton} >
											{pdfButton ? 'Loading...' : downloadPodPdf ? 'Loading...' : <> <i className='fe fe-download me-2' /> Download POD </>}
										</Button>

									}
									<Button className={`ms-2 ${statusButtonColor(shipment?.status)}`}><i className={statusIcon(shipment?.status)} />{" "}
										{shipment?.status === "EXCEPTION" ? "Cancelled" : ShipmentStatus[shipment?.status]}
									</Button>
								</Col>
							</Row>
						</div>
					</Container>
				</nav >

				<Modal show={shipToModal}>
					<Modal.Header>
						<Modal.Title className="modal-title">Ship To Address</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<Row>
							<Col lg={6} md={6}>
								<Form.Group className="mb-3">
									<FormLabel required> First Name</FormLabel>
									<Form.Control
										type="text"
										name="firstName"
										placeholder="e.g. John"
										defaultValue={shipTo?.firstName}
										onChange={handleShipToChange}
										isInvalid={!!errors?.name}
									/>
									<Form.Control.Feedback type="invalid">
										{errors?.first_name}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col lg={6} md={6}>
								<Form.Group className="mb-3">
									<FormLabel>Last Name</FormLabel>
									<Form.Control
										type="text"
										name="lastName"
										placeholder="e.g. doe"
										defaultValue={shipTo?.lastName}
										onChange={handleShipToChange}
									/>
								</Form.Group>
							</Col>
						</Row>

						<Form.Group className="mb-3">
							<Form.Label>Company</Form.Label>
							<Form.Control
								type="text"
								name="company"
								placeholder="e.g. PhoxHealth Inc"
								defaultValue={shipTo?.company}
								onChange={handleShipToChange}
							/>
						</Form.Group>
						<Row>
							<Col lg={6} md={6}>
								<Form.Group className="mb-3">
									<FormLabel>Email</FormLabel>
									<Form.Control
										type="text"
										name="email"
										placeholder="e.g. john@phoxhealth.com"
										value={shipTo?.email}
										onChange={handleShipToChange}
									/>
									<FormError error={errors?.email} />
								</Form.Group>
							</Col>
							<Col lg={6} md={6}>
								<Form.Group className="mb-3">
									<FormLabel>Phone</FormLabel>
									<PhoneInput
										name="phone"
										country="us"
										onlyCountries={["us"]}
										value={shipTo?.phone || ""}
										placeholder="e.g. 998-776-5543"
										onChange={handlePhoneChange}
									/>
									<FormError error={errors?.phone} />
								</Form.Group>
							</Col>
						</Row>
						<Form.Group className="mb-3">
							<FormLabel required>Address Line 1 </FormLabel>
							<GoogleAddressField
								name="address.address1"
								value={shipTo?.address?.address1}
								onChange={handleAddress1Change}
								onSelect={handleAddressSelect}
								isInvalid={!!errors?.address1}
								error={errors?.address1}


							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<FormLabel>Address Line 2 </FormLabel>
							<Form.Control
								type="text"
								name="address2"
								placeholder="e.g. near city center"
								defaultValue={shipTo?.address?.address2}
								onChange={handleAddress2Change}
							/>
						</Form.Group>
						<Row>
							<Col lg={4} md={4}>
								<Form.Group className="mb-3">
									<FormLabel required>City</FormLabel>
									<Form.Control
										type="text"
										placeholder="e.g. +1 New York"
										value={shipTo?.address?.city}
										disabled
									/>
									<FormError error={errors?.city} />
								</Form.Group>
							</Col>
							<Col lg={4} md={4}>
								<Form.Group>
									<FormLabel required>State</FormLabel>
									<Form.Control
										type="text"
										placeholder="e.g. NY"
										value={shipTo?.address?.state}
										disabled
									/>
									<FormError error={errors?.state} />
								</Form.Group>
							</Col>
							<Col lg={4} md={4}>
								<Form.Group className="mb-3">
									<FormLabel required>Postal Code</FormLabel>
									<Form.Control
										type="text"
										placeholder="e.g. 10018"
										value={shipTo?.address?.postalCode}
										disabled
									/>
									<FormError error={errors?.postalCode} />
								</Form.Group>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer className="display-flex-start">
						<Spinner display={spinner}>
							<button
								className="btn btn-dark ms-2"
								onClick={() => updateShipTo()}
							>
								Update
							</button>
							<button
								onClick={() => {
									showShipToModal(false);
									setShipTo(shipment?.shipTo);
									setErrors();
								}}
								className="btn btn-link text-muted"
							>
								Cancel
							</button>
						</Spinner>
					</Modal.Footer>
				</Modal>
			</div>
		);
	};


	const ShipmentDetails = () => {
		const [spinner, showSpinner] = useState(false);
		const [myShipment, setMyShipment] = useState(shipment);
		const [shipmentModal, showShipmentModal] = useState(false);
		const [errors, setErrors] = useState();
		let storageTypes =
			(myShipment?.services?.includes("COLD_CHAIN") || myShipment?.services?.includes("ROOM_TEMPERATURE")) &&
			myShipment?.services?.map((key, index) =>
				index !== myShipment?.services?.length - 1
					? StorageType[key] === undefined
						? ""
						: StorageType[key] + ", "
					: StorageType[key]
			);

		useEffect(() => {
			let photo_Id = shipment?.shipper?.settings?.items.find(x => x.key === 'shipment_photo_id_required')
			if (photo_Id?.value === "Yes") {
				SignatureType.PHOTO_ID = "Photo ID Required"
			}
		}, [])

		const handleShipmentServiceChange = (service) => {
			let services = myShipment?.services;
			if (services) {
				if (services.includes(service)) {
					services = services.filter(x => x !== service);
					if ((shipment?.shipper?.settings?.items.find(x => x.key === 'shipment_photo_id_required')?.value === "Yes")) {
						if (service === "ADULT_SIGNATURE" && services.includes("PHOTO_ID")) {
							services = services.filter(x => x !== "PHOTO_ID");
						}
					}
				} else {
					if (!services.includes(service)) {
						const conflictMap = {
							COLD_CHAIN: 'ROOM_TEMPERATURE',
							ROOM_TEMPERATURE: 'COLD_CHAIN',
						};
						const conflictingService = conflictMap[service];
						if (conflictingService && services.includes(conflictingService)) {
							services = services.filter(x => x !== conflictingService);
						}
						services.push(service);
					}
					if ((shipment?.shipper?.settings?.items.find(x => x.key === 'shipment_photo_id_required')?.value === "Yes")) {
						if (service === "PHOTO_ID" && !services?.includes("ADULT_SIGNATURE")) {
							services.push("ADULT_SIGNATURE")
						}
					}
				}
			} else {
				services = [service];
			}
			setMyShipment({ ...myShipment, services });
		};


		const isValid = () => {
			let error = {};
			if (myShipment?.weight < 0) error.weight = 'Please enter a valid weight'
			if (myShipment?.weight?.toString()?.trim()?.startsWith('.')) error.weight = 'Please enter a valid weight'
			if (myShipment?.carrier?.name === 'FedEx') {
				if (!myShipment?.weight) error.weight = 'Weight is required'
			}

			setErrors(error)
			return Object.keys(error).length === 0;
		}


		const updateShipmentDetails = () => {
			if (myShipment?.weight == 0) return toast.error("Please enter valid weight!")
			if (!myShipment?.services?.includes('COLD_CHAIN') && !myShipment?.services?.includes('ROOM_TEMPERATURE')) {
				return toast.error('Please select a package type');
			}
			if (myShipment?.carrier?.name === 'FedEx' && !isValid()) return;
			showSpinner(true);
			const input = {
				id,
				deliveryInstructions: myShipment?.deliveryInstructions,
				services: myShipment?.services,
				weight: myShipment?.weight
			};

			API.graphql({ query: updateShipment, variables: { input } })
				.then(
					async ({ data }) => {
						await processBilling(id);
						getShipment();
						showShipmentModal(false);
						toast.success(`Shipment has been updated`);
					}
				).catch((error) => handleApiError(error))
				.finally(() => showSpinner(false));
		};

		let trackingUrl = "";
		if (
			shipment?.carrier?.name?.toLowerCase() === "fedex" &&
			shipment?.extTrackingNumber
		) {
			trackingUrl = `https://www.fedex.com/fedextrack/?trknbr=${shipment?.extTrackingNumber}`;
		} else if (
			shipment?.carrier?.name?.toLowerCase() === "usps" &&
			shipment?.extTrackingNumber
		) {
			trackingUrl = `https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${shipment?.extTrackingNumber}`;
		} else if (
			shipment?.carrier?.name?.toLowerCase() === "ups" &&
			shipment?.extTrackingNumber
		) {
			trackingUrl = `https://www.ups.com/track?track=yes&trackNums=${shipment?.extTrackingNumber}`;
		}

		return (
			<>
				<Card
					title="Shipment Details"
					bodyPadding="py-0"
					buttonName={(shipment?.status === "READY_FOR_PICKUP" || shipment?.status === 'OPEN' || shipment?.status === 'OUT_FOR_DELIVERY') && (isOwner(myShipper?.role) || isEditor(myShipper?.role)) ? "Edit" : ""}
					onClick={() => showShipmentModal(true)}
				>
					<ListGroup className="list-group-flush">
						{
							shipment?.isGreenPhox && <ListGroupItem name="Box Number" value={shipment?.box?.number || shipment?.box?.name} url={`/box/details/${id}`} />
						}
						<ListGroupItem name="Shipper" value={shipment?.shipFrom?.name} />
						<ListGroupItem name='Shipper Phone' value={shipment?.shipFrom?.phone || '-'} />
						<ListGroupItem name="Delivery Service" value={shipment?.deliveryService?.name} />
						<ListGroupItem name="Order / Reference Number" value={shipment?.extId} />
						<ListGroupItem name="Adult Signature" value={shipment?.services?.includes("ADULT_SIGNATURE") ? "Required" : "-"} />
						{shipment?.services?.includes("PHOTO_ID") && <ListGroupItem name="Photo ID" value={"Required"} />}
						<ListGroupItem name="Package Type" value={storageTypes ? storageTypes : "-"} />
						<ListGroupItem name="Weight" value={shipment?.weight ? `${shipment?.weight} lbs` : "-"} />
						<ListGroupItem name="Distance" value={`${shipment?.distance} mi`} />
						<ListGroupItem name="Tracking Number" value={shipment?.extTrackingNumber || "-"} url={trackingUrl} />
						<ListGroupItem name="Delivery Instructions" value={shipment.deliveryInstructions || "-"} />
					</ListGroup>
				</Card>

				<Modal
					show={shipmentModal}
					aria-labelledby="contained-modal-title-vcenter"
				>
					<Modal.Header>
						<Modal.Title className="modal-title">
							Update Shipment Details
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className="d-flex justify-content-between">
							<FormLabel>Adult Signature </FormLabel>
						</Form.Group>
						<Form.Group className="mb-3 d-flex justify-content-start">
							{Object.keys(SignatureType).map((key, index) => {
								return (
									<Button
										key={index}
										className={`btn btn-${myShipment?.services?.includes(key) ? "success" : "light"} me-2 mt-2`}
										onClick={() => handleShipmentServiceChange(key)}
									>
										{SignatureType[key]}
									</Button>
								);
							})}
						</Form.Group>
						{(shipment?.carrier?.name !== "FedEx" && shipment?.status !== 'OUT_FOR_DELIVERY') && <>

							<Form.Group className="d-flex justify-content-between">
								<FormLabel>Storage Type</FormLabel>
							</Form.Group>

							<Form.Group className="mb-3 d-flex justify-content-start">
								{Object.keys(StorageType).map((key, index) => {
									return (
										<>
											< Button
												key={index}
												className={`btn btn-${myShipment?.services?.includes(key) ? "success" : "light"
													} me-2 mt-2`}
												onClick={() => handleShipmentServiceChange(key)}
											>
												{StorageType[key]}
											</Button >
										</>
									);
								})}
								<FormError error={errors?.packageType} />
							</Form.Group>

						</>}
						{
							(shipment?.status !== 'OUT_FOR_DELIVERY' && shipment?.carrier?.name !== 'USPS') &&

							<div className="mb-3" onWheel={(e) => e.target.blur()}>
								<FormLabel>Package Weight (in lbs)
									{['fedex', 'usps'].includes(myShipment?.carrier?.name?.toLowerCase()) && <span className='text-danger ms-2'>*</span>}
								</FormLabel>
								<Form.Control type='number' placeholder='e.g. 2' defaultValue={shipment?.weight || ''} onChange={(e) => setMyShipment({ ...myShipment, weight: e.target.value })} />
								<FormError error={errors?.weight} />

							</div>

						}

						<Form.Group className="mb-3">
							<Form.Label>Delivery Instructions</Form.Label>
							<Form.Control
								as="textarea"
								defaultValue={myShipment?.deliveryInstructions}
								onChange={(e) =>
									setMyShipment({
										...myShipment,
										deliveryInstructions: e.target.value,
									})
								}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer className="display-flex-start">
						<Spinner display={spinner}>
							<button
								className="btn btn-dark ms-2"
								onClick={() => updateShipmentDetails()}
							>
								Update
							</button>
							<button
								className="btn btn-link text-muted"
								onClick={() => { showShipmentModal(false); getShipment() }}
							>
								Cancel
							</button>
						</Spinner>
					</Modal.Footer>
				</Modal >
			</>
		);
	};

	const MultiDimensionalShipmentItems = () => {
		const [items, setItems] = useState(shipment?.items);
		const [itemModal, showItemModal] = useState(false);
		const [itemIndex, setItemIndex] = useState();
		const [spinner, showSpinner] = useState(false);
		const [ndcAndQtyFlag, setNdcAndQtyFlag] = useState(false)
		const [packageErrors, setPackageErrors] = useState([]);

		const searchProducts = async (keyword) => {
			try {
				const apiName = 'api';
				const path = `/search/product?size=1000&from=0`;
				const init = {
					body: {
						"sort": [
							{
								"name.keyword": {
									"order": "asc"
								}
							}
						],
						"query": {
							"bool": {
								"must": [
									{
										"match": {
											"shipperId": myShipper?.shipper.id
										}
									}
								]
							}
						}
					},
				};
				if (keyword) {
					let fields = ["name"]
					init.body.query.bool.must.push(
						{
							"multi_match": {
								"query": keyword,
								"fields": fields
							}
						}
					)
				}
				const { hits } = await API.post(apiName, path, init);
				const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];

				return sourceData.map((item) => ({
					value: item.id,
					label: item.name,
				}));
			} catch (error) {
				handleApiError(error)
				showSpinner(false);

			}
		};

		const addShipmentItem = () => {
			let myItems = [...items[itemIndex]];
			ndcAndQtyFlag ? myItems.push({ id: "NEW", name: "Product", number: "", ndc: "", qty: "", tier: 0 }) : myItems.push({ id: "NEW", name: "Product", number: "" });
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const removeShipmentItem = (index) => {
			let myItems = [...items[itemIndex]];
			myItems.splice(index, 1);
			let newPackages = [...items];
			newPackages[itemIndex] = myItems;
			setItems(newPackages);
			let errorList = [];
			const numberMap = new Map();

			myItems.forEach((item, idx) => {
				let errors = {};
				if (!item.number || item.number.trim() === '') {
					errors.number = 'Reference no. is required';
				}
				if (!item.ndc || item.ndc.trim() === '') {
					errors.ndc = 'NDC is required';
				}
				if (!item.qty || item.qty.trim() === '') {
					errors.qty = 'QTY is required';
				}

				const refNumber = item.number;
				if (refNumber && numberMap.has(refNumber)) {
					errors.duplicateReferenceNumber = 'Duplicate reference number.';
				} else if (refNumber) {
					numberMap.set(refNumber, { idx });
				}

				if (Object.keys(errors).length > 0) {
					errorList.push({ index: idx, errors });
				}
			});
			setPackageErrors(errorList);
		};

		const handleItemProductChange = (index, id, name) => {
			let myItems = [...items[itemIndex]];
			myItems[index].id = id;
			myItems[index].name = name;
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const handleItemNumberChange = (index, value) => {
			let myItems = [...items[itemIndex]];
			myItems[index].number = value?.trim();
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const handleItemNdcChange = (index, value) => {
			let myItems = [...items[itemIndex]];
			myItems[index].ndc = value?.trim();
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const handleItemQtyChange = (index, value) => {
			let myItems = [...items[itemIndex]];
			myItems[index].qty = value?.trim();
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};
		const handleSelectTier = (index, value) => {
			let myItems = [...items[itemIndex]];
			myItems[index].tier = value;
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};


		const validatePackageData = () => {
			let errorList = [];
			const numberMap = new Map();
			items.forEach((pkg, pkgIndex) => {
				pkg.forEach((item, itemIndex) => {
					let errors = {};
					if (!item.number || item.number.trim() === '') {
						errors.number = 'Reference no. is required.';
					}
					if (!item.ndc || item.ndc.trim() === '') {
						errors.ndc = 'NDC is required.';
					}
					if (!item.qty || item.qty.trim() === '') {
						errors.qty = 'QTY is required.';
					}

					const refNumber = item.number;
					if (refNumber && numberMap.has(refNumber)) {
						errors.duplicateReferenceNumber = 'Duplicate reference number.';
					} else if (refNumber) {
						numberMap.set(refNumber, { pkgIndex, itemIndex });
					}

					if (Object.keys(errors).length > 0) {
						errorList.push({ index: itemIndex, errors });
					}
				});
			});
			setPackageErrors(errorList);

			return errorList
		}


		const updateShipmentItems = () => {
			let error = []
			if (ndcAndQtyFlag) {
				error = validatePackageData()
			}
			if (error.length > 0) return
			showSpinner(true);
			const input = { id, items: JSON.stringify(items), packageCount: items?.length };
			API.graphql({ query: updateShipment, variables: { input } }).then(
				async ({ data }) => {
					await processBilling(id)
					toast.success(`Package items has been updated`);
					getShipment();
					showItemModal(false);
					showSpinner(false);
				}
			).catch((error) => {
				handleApiError(error)
			}).finally(() => {
				showSpinner(false);
			});
		};

		const addPackages = () => {
			items[0].some((item) => {
				if (item?.ndc || item?.qty) {
					setNdcAndQtyFlag(true)
					return
				}
			})

			let addedNewPackage = JSON.parse(JSON.stringify(items))
			if (ndcAndQtyFlag) {
				addedNewPackage.push([{
					id: 'rx',
					name: 'Product',
					number: '',
					ndc: '',
					qty: ''
				}])

			} else {
				addedNewPackage.push([{
					id: 'rx',
					name: 'Product',
					number: ''
				}])

			}

			setItems(addedNewPackage)
			setItemIndex(addedNewPackage.length - 1)
			showItemModal(true)
		}

		const handleRemovePackage = (boxIndex) => {
			swal({
				text: `If you proceed, you will lose the Package data. Are you sure you want to continue?`,
				buttons: ['No', 'Yes'],
				icon: 'error',
				dangerMode: true,
			}).then(async (status) => {
				if (status) {
					let packages = [...items];
					packages.splice(boxIndex, 1)
					try {
						showSpinner(true)
						const input = { id, items: JSON.stringify(packages), packageCount: packages.length };
						API.graphql({ query: updateShipment, variables: { input } }).then(
							async () => {
								await processBilling(id)
								toast.success(`Package items has been updated`);
								getShipment();
								showItemModal(false);
								showSpinner(false);
							}
						);

					} catch (error) {
						showItemModal(false);
						showSpinner(false);
						handleApiError(error)
					}
				}
			});

		}


		const ItemsContainer = ({ value, index }) => {
			return <div className='list-group-item'>
				<h5 className='my-2'>{`${index + 1} - ${value?.name}`}</h5>
				<div className="d-flex justify-content-between flex-wrap gap-1">
					<span className='small w-50 text-muted'>{value?.number}</span>
					<span className='small  text-muted'>{value?.ndc}</span>
					<span className='small text-muted'>{value?.qty}</span>
					<span className='small text-muted'>{value?.tier}</span>
				</div>

			</div>
		}


		const getErrorMessage = (index, field) => {
			const error = packageErrors.find((err) => err.index === index);
			return error ? error.errors[field] : '';
		};


		return (
			<>
				<div>
					<div className="d-flex justify-content-between py-3 align-items-center">
						<h4>
							{`Package content (${items?.length})`}
						</h4>
						{
							items?.length < 5 && (!isViewer(myShipper?.role)) && ['OPEN', 'READY_FOR_PICKUP']?.includes(shipment?.status) &&
							<button className="btn btn-light btn-sm" onClick={() => addPackages()} >Add Package</button>
						}
					</div>


					{shipment?.items?.map((box, boxIndex) => (
						<div className="card" key={boxIndex}>
							<div div className="card-header d-flex justify-content-between pb-0" >
								<h4>{`Package ${boxIndex + 1}`}</h4>
								<div >
									{
										['OPEN', 'READY_FOR_PICKUP']?.includes(shipment?.status) && (!isViewer(myShipper?.role)) &&
										<>
											<div className="btn btn-light btn-sm me-2" onClick={() => {
												showItemModal(true);
												setItemIndex(boxIndex);
												items[0]?.some((item) => {
													if (item?.ndc || item?.qty) {
														setNdcAndQtyFlag(true)
														return
													}
												})
											}}> <i className="fe fe-edit-3"></i> </div>
											{items?.length > 1 &&
												<div className="btn btn-light btn-sm" onClick={() => handleRemovePackage(boxIndex)} > <i className="fe fe-trash"></i> </div>
											}
										</>
									}
								</div>
							</div>

							<div className="card-body px-lg-3 px-xl-4 px-md-4 px-sm-4 py-0">

								<ListGroup className="list-group-flush py-0" key={boxIndex}>
									{
										box?.map((item, index) => (

											<>
												{
													(item?.ndc || item?.qty) ? <ItemsContainer
														key={index}
														value={item}
														index={index}
													/> :
														<ListGroupItem
															key={index}
															name={`${index + 1} - ${item?.name}`}
															value={item.number}
														/>
												}
											</>
										))
									}
								</ListGroup>
							</div>

						</div >
					))}

				</div >

				<Modal
					size='xl'
					show={itemModal}
					aria-labelledby="contained-modal-title-vcenter"
				>
					<Modal.Header>
						<Modal.Title className="modal-title">
							Update Package Items
						</Modal.Title>
						<button
							className="btn btn-sm btn-light mb-2"
							onClick={() => addShipmentItem()}
						>
							Add Item
						</button>
					</Modal.Header>
					<Modal.Body className="p-0">
						<Table className="table table-sm mb-0 p-0">
							<thead>
								<tr>
									<th className="text-center">#</th>
									<th>Product</th>
									<th>Reference Number  {ndcAndQtyFlag && <span className='text-danger'> *</span>} </th>
									{
										ndcAndQtyFlag && <>
											<th className="text-center"> NDC  <span className='text-danger'> *</span>  </th>
											<th className="text-center"> QTY <span className='text-danger'> *</span> </th>
											<th className="text-center"> PHOXTAIL GUARANTEE</th>
										</>
									}
									<th></th>
								</tr>
							</thead>
							<tbody className="list font-size-base">
								{items &&
									items[itemIndex]?.map((item, index) => {
										return (
											<tr key={index}>
												<td className="text-center">{`${index + 1}`}</td>
												<td>
													<AsyncSelect
														cacheOptions
														defaultOptions
														value={{
															label: item?.name || "Search Product",
															value: item?.id,
														}}
														placeholder="Search product"
														loadOptions={searchProducts}
														onChange={(e) =>
															handleItemProductChange(index, e.value, e.label)
														}
													/>
												</td>
												<td>
													<input
														className={`${(getErrorMessage(index, 'number') || getErrorMessage(index, 'duplicateReferenceNumber')) && "mt-4"}  form-control`}
														key={index}
														type="text"
														name={`orderNumber-${index}`}
														placeholder="e.g. 1112-12322"
														value={item?.number}
														onChange={(e) =>
															handleItemNumberChange(index, e.target.value)
														}
													/>
													{getErrorMessage(index, 'number') ?
														<FormError error={getErrorMessage(index, 'number')} />
														: <FormError error={getErrorMessage(index, 'duplicateReferenceNumber')} />}
												</td>
												{(ndcAndQtyFlag || (item?.ndc || item?.qty)) && <>
													<td>
														<input
															className={`${getErrorMessage(index, 'ndc') && "mt-4"}  form-control`}
															key={index}
															type='text'
															name={`ndc-${index}`}
															placeholder='e.g. Metformin'
															value={item?.ndc}
															onChange={(e) =>
																handleItemNdcChange(index, e.target.value)
															}
														/>
														<FormError error={getErrorMessage(index, 'ndc')} />
													</td>
													<td>
														<input
															className={`${getErrorMessage(index, 'qty') && "mt-4"}  form-control`}
															key={index}
															type='text'
															name={`qty-${index}`}
															placeholder='e.g. 100gm'
															value={item?.qty}
															onChange={(e) =>
																handleItemQtyChange(index, e.target.value)
															}
														/>
														<FormError error={getErrorMessage(index, 'qty')} />
													</td>
													<td>
														<Form.Select value={item?.tier} onChange={(e) => handleSelectTier(index, e.target.value)}>
															<option key={0} value={0}>Tier 0: $100 </option>
															<option key={1} value={1}>Tier 1: $5,000 </option>
															<option key={2} value={2}>Tier 2: $20,000</option>
															<option key={3} value={3}>Tier 3: $40,000 </option>
															<option key={4} value={4}>Tier 4: $60,000 </option>
														</Form.Select>
													</td>
												</>
												}
												<td className="text-center">
													{items[itemIndex].length > 1 && (
														<button
															className="btn btn-sm btn-outline-secondary"
															onClick={() => removeShipmentItem(index)}
														>
															<i className="fe fe-trash"></i>
														</button>
													)}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>

					</Modal.Body>
					<Modal.Footer className="display-flex-start">
						<Spinner display={spinner}>
							<button
								className="btn btn-dark ms-2"
								onClick={() => updateShipmentItems()}
							>
								Update
							</button>
							<button
								className="btn btn-link text-muted"
								onClick={() => { showItemModal(false); setItems(JSON.parse(JSON.stringify(shipment?.items))); setNdcAndQtyFlag(false); setPackageErrors([]) }}
							>
								Cancel
							</button>
						</Spinner>
					</Modal.Footer>
				</Modal >
			</>
		);
	};


	const ShipmentItems = () => {
		const [spinner, showSpinner] = useState(false);
		const [items, setItems] = useState(shipment?.items);
		const [itemModal, showItemModal] = useState(false);

		const searchProducts = async (keyword) => {
			try {
				const apiName = 'api';
				const path = `/search/product?size=1000&from=0`;
				const init = {
					body: {
						query: {
							bool: {
								must: [
									{
										match: {
											shipperId: shipment?.shipperId
										}
									}
								]
							}
						}
					},
				};
				if (keyword) {
					let fields = ["name"]
					init.body.query.bool.must.push(
						{
							"multi_match": {
								"query": keyword,
								"fields": fields
							}
						}
					)

				}
				const { hits } = await API.post(apiName, path, init);
				const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];

				return sourceData.map((item) => ({
					value: item.id,
					label: item.name,
				}));
			} catch (error) {
				handleApiError(error)
				showSpinner(false);

			}
		};

		const addShipmentItem = () => {
			let myItems = [...items];
			myItems.push({ id: "NEW", name: "Product", number: "" });
			setItems(myItems);
		};

		const removeShipmentItem = (index) => {
			let myItems = [...items];
			myItems.splice(index, 1);
			setItems(myItems);
		};

		const handleItemProductChange = (index, id, name) => {
			let myItems = [...items];
			myItems[index].id = id;
			myItems[index].name = name;
			setItems(myItems);
		};

		const handleItemNumberChange = (index, value) => {
			let myItems = [...items];
			myItems[index].number = value;
			setItems(myItems);
		};

		const updateShipmentItems = () => {
			showSpinner(true);
			const input = { id, items: JSON.stringify(items) };
			API.graphql({ query: updateShipment, variables: { input } })
				.then(
					async ({ data }) => {
						toast.success(`Package items has been updated`);
						await processBilling(id);
						getShipment();
						showItemModal(false);
						showSpinner(false);
					}
				).catch((error) => handleApiError(error));
		};

		return (
			<>
				<Card
					title="Items"
					bodyPadding="py-0"
					buttonName={
						(shipment?.status === "READY_FOR_PICKUP" || shipment?.status === "OPEN") &&
							(!isViewer(myShipper?.role))
							? "Edit"
							: ""
					}
					onClick={() => showItemModal(true)}
				>
					<ListGroup className="list-group-flush">
						{shipment?.items?.map((item, index) => (
							<ListGroupItem
								key={index}
								name={`${index + 1} - ${item?.name}`}
								value={item.number}
							/>
						))}
					</ListGroup>
				</Card>

				<Modal
					show={itemModal}
					aria-labelledby="contained-modal-title-vcenter"
					onHide={() => showItemModal(false)}
				>
					<Modal.Header>
						<Modal.Title className="modal-title">
							Update Package Items
						</Modal.Title>
						<button
							className="btn btn-sm btn-light mb-2"
							onClick={() => addShipmentItem()}
						>
							Add Item
						</button>
					</Modal.Header>
					<Modal.Body className="p-0">
						<Table className="table table-sm mb-0 p-0">
							<thead>
								<tr>
									<th className="text-center">#</th>
									<th>Product</th>
									<th>Reference Number</th>
									<th></th>
								</tr>
							</thead>
							<tbody className="list font-size-base">
								{items &&
									items?.map((item, index) => {
										return (
											<tr key={index}>
												<td className="text-center">{`${index + 1}`}</td>
												<td>
													<AsyncSelect
														cacheOptions
														defaultOptions
														value={{
															label: item?.name || "Search Product",
															value: item?.id,
														}}
														placeholder="Search product"
														loadOptions={searchProducts}
														onChange={(e) =>
															handleItemProductChange(index, e.value, e.label)
														}
													/>
												</td>
												<td>
													<input
														className="form-control"
														key={index}
														type="text"
														name={`orderNumber-${index}`}
														placeholder="e.g. 1112-12322"
														value={item?.number}
														onChange={(e) =>
															handleItemNumberChange(index, e.target.value)
														}
													/>
												</td>
												<td className="text-center">
													{index > 0 && (
														<button
															className="btn btn-sm btn-outline-secondary"
															onClick={() => removeShipmentItem(index)}
														>
															<i className="fe fe-trash"></i>
														</button>
													)}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</Modal.Body>
					<Modal.Footer className="display-flex-start">
						<Spinner display={spinner}>
							<button
								className="btn btn-dark ms-2"
								onClick={() => updateShipmentItems()}
							>
								Update
							</button>
							<button
								className="btn btn-link text-muted"
								onClick={() => {
									showItemModal(false);
									setItems(shipment?.items);
								}}
							>
								Cancel
							</button>
						</Spinner>
					</Modal.Footer>
				</Modal>
			</>
		);
	};


	const ShipmentRating = () => {
		const rating =
			shipment?.ratings?.items?.length > 0 ? shipment?.ratings?.items[0] : null;
		return (
			<Card title="Customer Rating">
				<p>{rating?.feedback || "-"}</p>
				<Row>
					<Col>
						<StarRatings
							rating={rating?.rating || 0}
							starRatedColor="#FFCD3C"
							starDimension="24px"
							starSpacing="1px"
						/>
					</Col>
					<Col className="col-auto">
						{rating?.nps ? <div className={`nps nps-${(rating?.nps || 0) * 10}`}></div> : ""}
					</Col>
				</Row>
			</Card>
		);
	};

	const RecentActivity = () => {
		return (
			<>
				<Card title="Recent Activity">
					<ListGroup className="list-group-flush list-group-activity my-n3">
						{shipment?.history &&
							shipment?.history
								.sort((a, b) => b.createdTime - a.createdTime)
								.map((item, index) => {
									return (
										<ListGroup.Item key={index}>
											<Row>
												<Col className="col-auto">
													<div className="avatar avatar-sm">
														<div
															className={`avatar-title fs-lg badge bg-${statusBadgeColor(
																item?.status
															)} rounded-circle`}
														>
															<i className={statusIcon(item?.status)}></i>
														</div>
													</div>
												</Col>
												<Col className="activity-alignment" >
													{ShipmentStatus[item.status]}
													<div className="small text-muted">
														<div>{item.description}</div>
														<div className="mt-2">
															<i className="fe fe-user" />{" "}
															{item?.user?.name || "Admin"}
														</div>
													</div>
												</Col>
												<Col className="col-auto activity-alignment activity-margin">
													<div className="small ">
														{toLocalTime(
															item?.createdTime,
															shipment?.shipFrom?.timezone?.id
														)}
													</div>
													<div className="small text-muted ">
														{toLocalDate(
															item?.createdTime,
															shipment?.shipFrom?.timezone?.id
														)}
													</div>
												</Col>
											</Row>
										</ListGroup.Item>
									);
								})}
					</ListGroup>
				</Card>
			</>
		);
	};



	const DeliveryProof = () => {
		const [history, setHistory] = useState();
		useEffect(() => {
			const index = shipment?.history?.findIndex(
				(x) => x.status === "DELIVERED" || 'RETURNED'
			);
			if (index >= 0) {
				let historyItem = shipment?.history[index];
				if (historyItem.signature && typeof historyItem.signature === "string")
					historyItem.signature = JSON.parse(historyItem.signature);
				setHistory(historyItem);
			}
		}, []);

		let closedByOtp = false;
		const otp = shipment?.history?.find(item => item?.description?.toLowerCase() === "closed by pin");
		if (otp) { closedByOtp = true; }

		return (
			<>
				<Card title="Proof of Delivery" bodyPadding="py-0">
					<ListGroup className="list-group-flush">
						<ListGroupItem
							key="name"
							name="Signed By"
							value={history?.signature?.name || "-"}
						/>
						<ListGroupItem
							key="relationship"
							name="Relationship"
							value={history?.signature?.relationship || "-"}
						/>
						{history?.signature?.isContactLess === "true" &&
							<ListGroupItem
								key="isContactLess"
								name="Contactless"
								value="Yes"
							/>}
						{history?.signature?.reason &&
							<ListGroupItem
								key="reason"
								name="Reason"
								value={history?.signature?.reason || "-"}
							/>}
						{closedByOtp &&
							<ListGroupItem
								key='verification_code'
								name='One-Time Passcode'
								value={closedByOtp ? shipment?.authCode : '-'} />
						}
					</ListGroup>
					{shipment?.attachment?.length > 0 && (
						<div className="position-relative my-3">
							<ImageGallery
								showBullets={false}
								showThumbnails={false}
								lazyLoad={true}
								showPlayButton={false}
								items={shipment?.attachment?.map((item, index) => ({
									original: item,
								}))}
							/>
						</div>
					)}
				</Card>
			</>
		);
	};

	const CancelShipment = () => {
		const myUser = useSelector((state) => state.slice.USER);
		const [cancelShipmentModal, setCancelShipmentModal] = useState(false);
		const [description, SetDescription] = useState("");

		const cancelShipmentFunc = () => {
			if (["OPEN", "READY_FOR_PICKUP"].includes(shipment?.status)) {
				try {
					showSpinner(true);
					setCancelShipmentModal(false);

					let inputShipment = { id, status: "EXCEPTION" };
					const currentTimestamp = moment().unix();

					let slackAlert = '';
					if (shipment?.deliveryService?.slackChannel?.trim()) {
						slackAlert = shipment?.deliveryService?.slackChannel?.trim();
					} else {
						slackAlert = (shipment?.deliveryService?.type === 'TODAY' && shipment?.deliveryService?.tatMax <= 4) ? 'critical-alerts' : 'alerts'
					}

					const slackInput = {
						channel: slackAlert,
						message: `Shipment ${shipment?.number} (${shipment?.deliveryService?.name}) is CANCELLED`,
						user: `${myUser?.name} (${shipment?.shipFrom?.name})`
					}

					let inputShipmentHistory = {
						id: uniqid(),
						shipmentId: shipment.id,
						userId: myUser.id,
						status: "EXCEPTION",
						description: description,
						createdTime: currentTimestamp,
					};

					let task = []

					task.push(API.graphql({ query: updateShipment, variables: { input: inputShipment }, }))
					task.push(API.graphql({ query: createShipmentStatusHistory, variables: { input: inputShipmentHistory }, }),)
					task.push(API.graphql({ query: sendSlackAlert, variables: { input: slackInput }, }))
					if (shipment?.deliveryService?.type === 'TODAY') {
						task.push(API.graphql({ query: sendCarrierNotificationMutation, variables: { shipmentId: id } }))
					}
					shipment?.isGreenPhox && shipment?.boxId && task.push(API.graphql({ query: updateShipmentBoxMutation, variables: { input: { status: 'AVAILABLE', id: shipment?.boxId } }, }))
					shipment?.carrier?.name?.toLowerCase() === 'ups' && shipment?.extTrackingNumber && task.push(API.graphql({ query: cancelUpsLabelMutation, variables: { id } }))
					shipment?.carrier?.name?.toLowerCase() === 'usps' && shipment?.extTrackingNumber && task.push(API.graphql({ query: cancelEndiciaLabelMutation, variables: { id } }))
					shipment?.carrier?.name?.toLowerCase() === 'fedex' && shipment?.extTrackingNumber && task.push(API.graphql({ query: cancelFedexLabel, variables: { id } }))
					shipment?.carrier?.alias?.toLowerCase() === 'clockwork' && task.push(API.graphql({ query: cancelCxtLabelMutation, variables: { id } }))

					Promise.all(task).then(async () => {
						toast.success(`shipment has been Cancelled`);
						await processBilling(id);
						getShipment();
						showSpinner(false);
					});
				} catch (error) {
					handleApiError(error);
					showSpinner(false);
				}
			} else {
				showSpinner(false);
				toast.error("can't cancel the shipment")
			}

		};


		const getShipmentStatus = async () => {
			await API.graphql({ query: getShipmentQuery, variables: { id: id } })
				.then((data) => {
					let shipment = data?.data?.getShipment;
					if (shipment) {
						if (shipment.shipFrom)
							shipment.shipFrom = JSON.parse(shipment.shipFrom);
						if (shipment.shipTo) shipment.shipTo = JSON.parse(shipment.shipTo);
						if (shipment.items) shipment.items = JSON.parse(shipment.items);
						if (shipment.history?.items?.length > 0)
							shipment.history = shipment.history?.items?.sort(
								(a, b) => new Date(b.createdTime) - new Date(a.createdTime)
							);
						else shipment.history = [];

						if (!["OPEN", "READY_FOR_PICKUP"].includes(shipment?.status)) {
							toast.error(`Sorry, can't cancel the Shipment, because the current shipment status is ${ShipmentStatus[shipment?.status]}`)
							setShipment(shipment);
						} else {
							setCancelShipmentModal(true);
						}

					} else {
						console.error("shipment not found");
					}
				}).catch((error) => {
					handleApiError(error)
				})
		}


		return (
			<>
				{
					["OPEN", "READY_FOR_PICKUP"].includes(shipment?.status) && (
						<>
							<hr />
							<div className="d-flex justify-content-start">
								<Button
									variant="light"
									size="lg"
									onClick={() => { getShipmentStatus() }}
								>
									{" "}
									<i className="fe fe-x-circle" /> Cancel Shipment{" "}
								</Button>
							</div>
						</>
					)
				}

				<Modal
					show={cancelShipmentModal}
					aria-labelledby="contained-modal-title-vcenter"
					onHide={() => setCancelShipmentModal(false)}
				>
					<Modal.Header>
						<Modal.Title className="modal-title">
							Are you sure that you want to cancel this Shipment?
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className="mb-3">
							<FormLabel>Description</FormLabel>
							<Form.Control
								as="textarea"
								name="statusDescription"
								defaultValue={description}
								onChange={(e) => SetDescription(e.target.value)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer className="display-flex-start">
						<Spinner display={spinner}>
							<button
								className="btn btn-dark ms-2"
								onClick={() => cancelShipmentFunc()}
							>
								Yes, Cancel
							</button>
							<button
								onClick={() => {
									setCancelShipmentModal(false);
									SetDescription("");
								}}
								className="btn btn-link text-muted"
							>
								No
							</button>
						</Spinner>
					</Modal.Footer>
				</Modal>
			</>
		);
	};

	//#endregion


	const ShipperBilling = ({ shipperBilling }) => {
		const [accordion, openAccordion] = useState(false)

		function CustomToggle({ children, eventKey }) {
			const decoratedOnClick = useAccordionButton(eventKey, () =>
				console.log('totally custom!'),
			);

			return (
				<button
					type="button"
					className='btn btn-sm btn-white border-0'
					onClick={() => { decoratedOnClick(); openAccordion(!accordion) }}
				>
					{children}
				</button>
			);
		}

		return <>
			<Accordion>
				<div className="card" >
					<div className="card-header">
						<h4 className='card-header-title'>Shipper Billing</h4>
						<Col className='col-auto mx-2'>
							<h5 className="mt-3">
								{"$" + Number(shipperBilling?.total).toFixed(2) ?? 0.00}
							</h5>
						</Col>
						<Col className='col-auto mx-2'><CustomToggle eventKey="0"> <i className={`fe fe-${accordion ? "chevron-up" : "chevron-down"}`}></i> </CustomToggle></Col>

					</div>
					<Accordion.Collapse eventKey="0">
						<div className='card-body'>
							<ListGroup className='list-group-flush'>
								<ListGroupItem name='Delivery Fee' value={"$" + Number(shipperBilling?.delivery_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Processing Charge' value={"$" + Number(shipperBilling?.processing_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='GreenPhox Fee' value={"$" + Number(shipperBilling?.green_phox_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Phox Tail Fee' value={"$" + Number(shipperBilling?.phox_tail_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Return Fee' value={"$" + Number(shipperBilling?.return_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Fuel Surcharge' value={"$" + Number(shipperBilling?.fuel_surcharge).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Other Fee' value={"$" + Number(shipperBilling?.other_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Total' value={"$" + Number(shipperBilling?.total).toFixed(2) ?? 0.00} />
							</ListGroup>
						</div>
					</Accordion.Collapse>
				</div>
			</Accordion>
		</>
	}


	const CommunicationLog = () => {
		const [accordion, openAccordion] = useState(false)
		function CustomToggle({ children, eventKey }) {
			const decoratedOnClick = useAccordionButton(eventKey, () =>
				console.log('totally custom!'),
			);
			return (
				<button
					type="button"
					className='btn btn-sm btn-white border-0'
					onClick={() => { decoratedOnClick(); openAccordion(!accordion) }}
				>
					{children}
				</button>
			);
		}
		const handleRowClick = (seletedLogTypeitem) => {
			setSelectedLogType(seletedLogTypeitem.type)
			setSelectedShipment(seletedLogTypeitem)
			setDrawerOpen(true);
		};
		return (

			<Accordion>
				<div className="card" >
					<div className="card-header">
						<h4 className='card-header-title'>Communication Logs</h4>
						<Col className='col-auto mx-2'><CustomToggle eventKey="0"> <i className={`fe fe-${accordion ? "chevron-up" : "chevron-down"}`}></i> </CustomToggle></Col>
					</div>
					<Accordion.Collapse eventKey="0">
						<div className='card-body'>
							<ListGroupIconItem items={shipment.communicationLogs?.items} onItemClick={(seletedLogTypeitem) => handleRowClick(seletedLogTypeitem)} />
						</div>
					</Accordion.Collapse>
				</div>
			</Accordion>
		)
	}

	return (
		<>
			<Spinner display={spinner} >
				<div className='hide-on-print' >
					<ShipmentHeader />
					<ShipmentDetailMap shipment={shipment} viewState={viewState} />
					<Container fluid className="mt-5">

						<Row>
							<Col xl={8}>
								<Row>

									<Col xl={6} lg={6} md={4} sm={4} >
										<ShipmentDetails />
										{
											multi_Dimensional_Shipment_Item ? <MultiDimensionalShipmentItems /> : <ShipmentItems />
										}
									</Col>
									<Col xl={6} lg={6} md={4} sm={4}>
										<RecentActivity />
										<CarrierDriver shipment={shipment} deliveryServices={deliveryServices} carrierlist={carrierlist} id={id} getShipment={getShipment} getDeliveryServices={getDeliveryServices} qrCode={qrCode} key={shipment.deliveryServiceId} myShipper={myShipper?.shipper} />
										{shipment?.billing_shipper && < ShipperBilling shipperBilling={JSON.parse(shipment?.billing_shipper)} />}

									</Col>

									{shipment.communicationLogs?.items.length > 0 &&
										<CommunicationLog />
									}

								</Row>
							</Col>

							<Col xl={4} lg={6} md={4} sm={4} >
								<ShipmentRating />
								<DeliveryProof />
							</Col>
						</Row>

						{myShipper?.role !== "VIEWER" && <CancelShipment />}
					</Container>
				</div>
			</Spinner>
			<PrintLabel shipment={shipment} qrCode={qrCode} />

			<DownloadPOD shipment={shipment} multi_Dimensional_Shipment_Item={multi_Dimensional_Shipment_Item} setDownloadPodPdf={setDownloadPodPdf} downloadPodPdf={downloadPodPdf} />

			<BarcodeReader onError={handleScanError} onScan={handleScan} />
			{/* Drawer Component */}
			<SideDrawer
				show={drawerOpen}
				handleClose={() => setDrawerOpen(false)}
				items={selectedShipment}
				setSelectedLogType={setSelectedLogType}
				selectedLogType={selectedLogType}
			/>
		</>
	);
};

export default ShipmentDetail;
