/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { StatCard } from '../../components/Card';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { TableDateFilter, TableKeywordSearch, TablePagination, TableShipperFilter, TableClearFilter, TimeZoneFilter, TableShipperGroupFilter, RatingFilter, NPSFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, handleApiError, PAGE_SIZE, toLocalDate, toLocalTime } from '../../helpers';
import { storeShipper } from '../../stores/slice';

import ReactGA from "react-ga4"
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { DoughnutChart } from '../../components/Chart';
import { UserCarrierFilter } from '../../components/FilterWithIds';

const deliveryServicesByShipperId = /* GraphQL */ `
  query DeliveryServicesByShipperId(
    $shipperId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryServicesByShipperId(
      shipperId: $shipperId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        value
        carrier{
            id
            name
            alias
            image
			active
			timezone {
                alias
                id
                name
            }
        }
      }
      nextToken
      __typename
    }
  }
`;

const RatingDashboard = () => {

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/star-phox",
		})
	}, [])

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const sortDirections = useRef({});

	const myShipper = useSelector((state) => state.slice.SHIPPER);
	const myShippers = useSelector((state) => state.slice.SHIPPERS);
	const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP);

	const [spinner, showSpinner] = useState(false);
	const [ratings, setRatings] = useState([])
	const [resetDate, setRestDate] = useState(0);
	const [carrierList, setCarrierList] = useState([])
	const [carrier, setCarrier] = useState()
	const [keyword, setKeyword] = useState('')
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();
	const [ratingAverage, setRatingAverage] = useState(0)
	const [npsAverage, setNpsAverage] = useState(0)
	const [timeZone, setTimeZone] = useState("America/New_York");
	const [tz, setTz] = useState("EST");
	const [shipperRating, setShipperRating] = useState([]);
	const [carrierRating, setCarrierRating] = useState([]);
	const [responseAverage, setResponseAverage] = useState(0);
	const [starRating, setStarRating] = useState([]);
	const [ratingCount, setRatingCount] = useState(0);
	const [ratingFilter, setRatingFilter] = useState();
	const [npsFilter, setNpsFilter] = useState();
	const [elasticSort, setElasticSort] = useState({ field: "ratings.items.createdAt", order: "desc" });
	const [npsRating, setNpsRating] = useState([
		{ label: 'Promoters', color: '#00A76F', percentage: 0, count: 0 },
		{ label: 'Passives', color: '#FEC203', percentage: 0, count: 0 },
		{ label: 'Detractors', color: '#E84521', percentage: 0, count: 0 },
	])

	const [dateFilters, setDateFilters] = useState({
		fromDate: moment().tz(timeZone || 'America/New_York')?.subtract(6, 'days').startOf('day').unix(),
		toDate: moment().tz(timeZone || 'America/New_York')?.endOf('day').unix()
	});

	const getServices = () => {
		setCarrier(null)
		myShipper?.shipper?.id && API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId: myShipper?.shipper?.id } })
			.then((response) => {
				const deliveryServices = response.data.deliveryServicesByShipperId?.items.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }));

				let carriers = [];
				deliveryServices.forEach((item) => {
					if (!carriers.find(x => x?.id === item?.carrier?.id))
						carriers.push(item.carrier)
				});

				carriers = carriers.filter((carrier) => carrier?.active !== false).sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }))
				setCarrierList(carriers)
			})
			.catch((error) => handleApiError(error))
	}


	function caluclateAverageResponseRate(totalShipments, totalNPSCount, totalRatingCount) {
		let avg_response_rate;
		if (totalShipments != null && totalRatingCount > totalNPSCount) {
			avg_response_rate = (totalRatingCount / totalShipments) * 100;
		}
		else if (totalNPSCount != null) {
			avg_response_rate = (totalNPSCount / totalShipments) * 100;
		} else {
			avg_response_rate = 0
		}
		return avg_response_rate?.toFixed(1)
	}

	const getElasticRatings = async () => {
		showSpinner(true)
		try {
			const apiName = 'api';
			const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				body: {
					"aggs": {
						"carrier": {
							"terms": {
								"field": "carrier.name.keyword",
								"order": {
									"avg_rating": "desc"
								}
							},
							"aggs": {
								"avg_rating": {
									"avg": {
										"field": "ratings.items.rating"
									}
								},
								"rating_filter": {
									"bucket_selector": {
										"buckets_path": {
											"avgRating": "avg_rating.value"
										},
										"script": "params.avgRating > 0"
									}
								}
							}
						},
						"shipper": {
							"terms": {
								"field": "shipperId.keyword",
								"size": 100,
								"order": {
									"avg_rating": "desc"
								}
							},
							"aggs": {
								"shipper_name": {
									"top_hits": {
										"_source": ["shipper.name"],
										"size": 1,
										"sort": [
											{
												"createdTime": {
													"order": "desc"
												}
											}
										]
									}
								},
								"avg_rating": {
									"avg": {
										"field": "ratings.items.rating"
									}
								},
								"avg_nps": {
									"scripted_metric": {
										"init_script": "state.promoters = 0; state.detractors = 0; state.total = 0;",
										"map_script": `
											if (doc['ratings.items.nps'].size() > 0) {
											  def nps = doc['ratings.items.nps'].value;
											  if (nps >= 9) {
												state.promoters += 1;
											  } else if (nps <= 6) {
												state.detractors += 1;
											  }
											  state.total += 1;
											}
										  `,
										"combine_script": "return state;",
										"reduce_script": `
											int promoters = 0;
											int detractors = 0;
											int total = 0;
											
											for (s in states) {
											  promoters += s.promoters;
											  detractors += s.detractors;
											  total += s.total;
											}
											
											double promotersPercentage = 0.0;
											double detractorsPercentage = 0.0;
											double result = 0.0;
											
											if (total > 0) {
												promotersPercentage = (promoters / (double) total) * 100;
												detractorsPercentage = (detractors / (double) total) * 100;
												result = Math.round(promotersPercentage) - Math.round(detractorsPercentage);
											}
											
											return (int) Math.round(result)
										  `
									}
								},
								"total_ratings_count": {
									"value_count": {
										"field": "ratings.items.rating"
									}
								},
								"total_nps_count": {
									"value_count": {
										"field": "ratings.items.nps"
									}
								},
							}
						},
						"avg_rating": {
							"avg": {
								"field": "ratings.items.rating"
							}
						},
						"nps_stats": {
							"scripted_metric": {
								"init_script": "state.promoters = 0; state.passives = 0; state.detractors = 0; state.total = 0;",
								"map_script": `
									if (doc.containsKey('ratings.items.nps') && doc['ratings.items.nps'].size() > 0) {
										def nps = doc['ratings.items.nps'].value;
										state.total += 1;
										
										if (nps > 8) {
											state.promoters += 1;
										} else if (nps >= 7 && nps <= 8) {
											state.passives += 1;
										} else {
											state.detractors += 1;
										}
									}
								`,
								"combine_script": "return state;",
								"reduce_script": `
								int promoters = 0;
								int passives = 0;
								int detractors = 0;
								int total = 0;

								// Aggregate values across all shards
								for (s in states) {
									promoters += s.promoters;
									passives += s.passives;
									detractors += s.detractors;
									total += s.total;
								}

								// Calculate percentages if total ratings are available
								if (total > 0) {
									double promotersPercentage = (promoters / (double) total) * 100;
									double passivesPercentage = (passives / (double) total) * 100;
									double detractorsPercentage = (detractors / (double) total) * 100;

									double npsScore = Math.round((Math.round(promotersPercentage)) - (Math.round(detractorsPercentage)));

									return [
										"promoters": promoters,
										"promotersPercentage": promotersPercentage,
										"passives": passives,
										"passivesPercentage": passivesPercentage,
										"detractors": detractors,
										"detractorsPercentage": detractorsPercentage,
										"npsScore": npsScore,
										"total": total
									];
								} else {
									return [
										"promoters": promoters,
										"promotersPercentage": 0,
										"passives": passives,
										"passivesPercentage": 0,
										"detractors": detractors,
										"detractorsPercentage": 0,
										"npsScore": 0,
										"total": total
									];
								}
							`
							}
						},
						"total_ratings_count": {
							"value_count": {
								"field": "ratings.items.rating"
							}
						},
						"total_nps_count": {
							"value_count": {
								"field": "ratings.items.nps"
							}
						},
						"ratings_by_star": {
							"terms": {
								"field": "ratings.items.rating",
								"size": 5,
								"min_doc_count": 0,
								"order": { "_key": "desc" }
							},
							"aggs": {
								"rating_count": {
									"value_count": {
										"field": "ratings.items.rating"
									}
								}
							}

						},
					},
					query: {
						bool: {
							must: [
								{
									match: { status: "DELIVERED" }
								},
								{
									"exists": {
										"field": "ratings.items.feedback"
									}
								}
							]
						}
					}
				}
			}

			if (!keyword) {
				init.body.query.bool.must.push({
					range: {
						"actualDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				delete init.body.sort

				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("shipment.number")
				}
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (myShipperGroup?.shipperGroup?.id) init.body.query.bool.must.push({ match: { shipperGroupId: myShipperGroup?.shipperGroup?.id } })
			if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper.id } })
			else if (!myShipperGroup?.shipperGroup?.id && myShippers) {
				let shipperIdArr = []
				myShippers.forEach((item) => {
					shipperIdArr.push(item.shipper.id)
				})
				init.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
			}
			if (carrier) init.body.query.bool.must.push({ match: { "carrierId": carrier?.id } })

			if (ratingFilter) init.body.query.bool.must.push({ term: { "ratings.items.rating": ratingFilter.value } })

			if (npsFilter) {
				if (npsFilter.value === "promoters") {
					init.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 9, "lte": 10 } } })
				} else if (npsFilter.value === "passives") {
					init.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 7, "lte": 8 } } })
				} else if (npsFilter.value === "detractors") {
					init.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 1, "lte": 6 } } })
				}
			}

			if (elasticSort?.field) {
				init.body.sort = [
					{
						[elasticSort?.field]: {
							"order": elasticSort?.order,
							"unmapped_type": "date"
						}
					}
				]
			}
			let data = await API.post(apiName, path, init);

			setRatings(data?.hits?.hits)
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
			showSpinner(false)

			if (data?.aggregations?.carrier?.buckets?.length) {
				let filteredData = data?.aggregations?.carrier?.buckets?.filter((item) => item?.avg_rating?.value !== null && item)
				setCarrierRating(filteredData)
			}
			else setCarrierRating([]);

			if (data?.aggregations?.nps_stats) {
				let nps_stats = data?.aggregations?.nps_stats?.value
				let avg_nps = nps_stats?.npsScore ? Math.round(nps_stats?.npsScore) : 0
				let ratings = [
					{ label: 'Promoters', color: '#00A76F', percentage: nps_stats?.promotersPercentage, count: nps_stats?.promoters },
					{ label: 'Passives', color: '#FEC203', percentage: nps_stats?.passivesPercentage, count: nps_stats?.passives },
					{ label: 'Detractors', color: '#E84521', percentage: nps_stats?.detractorsPercentage, count: nps_stats?.detractors }
				]
				setNpsRating(ratings)
				setNpsAverage(avg_nps)
			} else {
				setNpsAverage(0);
				setNpsRating([
					{ label: 'Promoters', color: '#00A76F', percentage: 0, count: 0 },
					{ label: 'Passives', color: '#FEC203', percentage: 0, count: 0 },
					{ label: 'Detractors', color: '#E84521', percentage: 0, count: 0 },
				])
			}

			if (data?.aggregations?.ratings_by_star?.buckets?.length) {
				setStarRating(data?.aggregations?.ratings_by_star?.buckets)
				setRatingCount(data?.aggregations?.total_ratings_count?.value)

			} else {
				setStarRating([])
				setRatingCount(0)
			}

			if (data?.aggregations?.avg_rating?.value) {
				setRatingAverage(data?.aggregations?.avg_rating?.value)
			} else {
				setRatingAverage(0)
			}

			//*** get delivered data ***//
			const shipmentInit = {
				body: {
					"aggs": {
						"shipper": {
							"terms": {
								"field": "shipperId.keyword",
								"size": 100
							},
							"aggs": {
								"total_shipments_count": {
									"value_count": {
										"field": "id.keyword"
									}
								}
							}
						},
					},
					query: {
						bool: {
							must: [
								{
									match: { status: "DELIVERED" }
								}
							]
						}
					}
				}

			}

			if (!keyword) {
				shipmentInit.body.query.bool.must.push({
					range: {
						"actualDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {

				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("number")
				}
				shipmentInit.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (myShipperGroup?.shipperGroup?.id) shipmentInit.body.query.bool.must.push({ match: { shipperGroupId: myShipperGroup?.shipperGroup?.id } })
			if (myShipper?.shipper?.id) shipmentInit.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper.id } })
			else if (!myShipperGroup?.shipperGroup?.id && myShippers) {
				let shipperIdArr = []
				myShippers.forEach((item) => {
					shipperIdArr.push(item.shipper.id)
				})
				shipmentInit.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
			}
			if (carrier?.id) init.body.query.bool.must.push({ match: { carrierId: carrier?.id } })

			if (ratingFilter) shipmentInit.body.query.bool.must.push({ term: { "ratings.items.rating": ratingFilter.value } })

			if (npsFilter) {
				if (npsFilter.value === "promoters") {
					shipmentInit.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 9, "lte": 10 } } })
				} else if (npsFilter.value === "passives") {
					shipmentInit.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 7, "lte": 8 } } })
				} else if (npsFilter.value === "detractors") {
					shipmentInit.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 1, "lte": 6 } } })
				}
			}

			let deliveredData = await API.post(apiName, `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`, shipmentInit);

			if (deliveredData?.hits?.total?.value) {
				let avg_response_rate = caluclateAverageResponseRate(deliveredData?.hits?.total?.value, data?.aggregations?.total_nps_count?.value, data?.aggregations?.total_ratings_count?.value)
				setResponseAverage(avg_response_rate)
			} else setResponseAverage(0)

			if (data?.aggregations?.shipper?.buckets?.length && deliveredData?.aggregations?.shipper?.buckets?.length) {
				for (let item of data?.aggregations?.shipper?.buckets) {
					let total_shipments_count = 0;
					for (let hit of deliveredData?.aggregations?.shipper?.buckets) {
						if (item.key === hit.key) {
							total_shipments_count += hit?.total_shipments_count?.value
						}
					}
					item.total_shipments_count = total_shipments_count;
					let avg_response_rate = 0
					if (total_shipments_count) {
						avg_response_rate = caluclateAverageResponseRate(total_shipments_count, item.total_nps_count?.value, item.total_ratings_count?.value)
					}
					item.avg_response_rate = avg_response_rate
				}
				setShipperRating(data?.aggregations?.shipper?.buckets)
			} else setShipperRating([])



		} catch (error) {
			showSpinner(false)
			handleApiError(error)
		}

	}

	const clearFilters = () => {
		setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
		setDateFilters({
			fromDate: moment().tz('America/New_York')?.subtract(6, 'days').startOf('day').unix(),
			toDate: moment().tz('America/New_York')?.endOf('day').unix()
		});
		setCarrier(null)
		setTimeZone("America/New_York");
		setNpsFilter(null)
		setRatingFilter(null)
		setElasticSort({ field: "ratings.items.createdAt", order: "desc" })
		setTz("EST");
		dispatch(storeShipper(myShippers))
		setCarrierList([])
	}
	const tzHandle = (e) => {
		setTimeZone(e);
	};

	function formatStarRatingCount(count) {
		if (count > 1000) {
			return new Intl.NumberFormat('en-US', {
				notation: 'compact',
				maximumFractionDigits: 1
			}).format(count);
		}
		return count.toString();
	}


	const calculatePercentage = (count) => (count === 0 || ratingCount === 0) ? 0 : (count / ratingCount) * 100;

	const onSort = (field, data, set) => {
		const currentDirection = sortDirections.current[field] || "asc";
		const newDirection = currentDirection === "asc" ? "desc" : "asc";
		sortDirections.current[field] = newDirection;

		const getValue = (obj, path) =>
			path.replace(/\[(\d+)\]/g, '.$1')
				.split('.')
				.reduce((o, key) => (o ? o[key] : undefined), obj);

		if (data?.length) {
			const sortedData = [...data].sort((a, b) => {
				let aValue = getValue(a, field);
				let bValue = getValue(b, field);

				const aIsNumber = !isNaN(parseFloat(aValue)) && isFinite(aValue);
				const bIsNumber = !isNaN(parseFloat(bValue)) && isFinite(bValue);

				if (aIsNumber && bIsNumber) {
					aValue = parseFloat(aValue);
					bValue = parseFloat(bValue);
				} else {
					aValue = aValue?.toString() ?? '';
					bValue = bValue?.toString() ?? '';
				}

				if (aValue < bValue) {
					return newDirection === "asc" ? -1 : 1;
				}
				if (aValue > bValue) {
					return newDirection === "asc" ? 1 : -1;
				}
				return 0;
			});
			set(sortedData);
		}
	};


	function handleElasticSort(field) {
		if (field === elasticSort?.field) {
			if (elasticSort?.order === "desc") {
				setElasticSort((prev) => ({ ...prev, order: "asc" }))
			} else {
				setElasticSort((prev) => ({ ...prev, order: "desc" }))
			}
		} else {
			setElasticSort({ field, order: "desc" })
		}
	}

	useEffect(() => {
		if (myShipper?.shipper?.id) { getServices() }
	}, [myShipper, myShipperGroup])

	useEffect(() => {
		if (carrier?.timezone?.id) {
			setTimeZone(carrier?.timezone?.id)
			setTz(carrier?.timezone?.alias)
		} else if (myShipper?.shipper) {
			setTimeZone(myShipper?.shipper?.timezone?.id)
			setTz(myShipper?.shipper?.timezone?.alias)
		} else if (myShipperGroup?.shipperGroup?.timezone?.id) {
			setTimeZone(myShipperGroup?.shipperGroup?.timezone?.id)
			setTz(myShipperGroup?.shipperGroup?.timezone?.alias)
		}
		if (keyword) setPageCount(0)
		const delay = setTimeout(() => { getElasticRatings(); }, DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword, dateFilters, myShipper, pageNumber, carrier, myShippers, myShipperGroup, ratingFilter, npsFilter, elasticSort])

	return (
		<>
			<PageHeader name='StarPhox'>
				<Row>
					{myShipperGroup?.shipperGroup?.id && <TableShipperGroupFilter />}
					<TableShipperFilter hideAll={myShipperGroup?.shipperGroup?.id ? true : false} />
					{myShipper?.shipper?.id && <UserCarrierFilter onChange={setCarrier} value={carrier} carrierList={carrierList} />}
					<RatingFilter value={ratingFilter} onChange={setRatingFilter} />
					<NPSFilter value={npsFilter} onChange={setNpsFilter} />
					<TableDateFilter key={resetDate} timezone={myShipper?.shipper?.timezone?.id} onChange={setDateFilters} dateFilters={dateFilters} startOf={6} hideRange={["Tomorrow"]} />
					<TimeZoneFilter
						title={""}
						setTimeZone={setTimeZone}
						dark={true}
						onChange={tzHandle}
						tz={tz}
						setTz={setTz}
					/>
					<TableClearFilter onClick={clearFilters} />

				</Row>
			</PageHeader>

			<Container fluid>
				<Row>
					<StatCard title='Average Rating' value={ratingAverage?.toFixed(1) ?? '-'} icon='star' />
					<StatCard title='NPS' value={npsAverage ?? '-'} icon='thumbs-up' />
					<StatCard title='Response Rate' value={responseAverage ? responseAverage : '-'} icon='percent' />
				</Row>
				<Card className='p-3'>
					<Spinner display={spinner}>
						<Row md={1} lg={2} className='align-items-center'  >
							<Col className='px-4 border-end'>
								<div className="rating-summary">
									<div className="d-flex align-items-center mb-3">
										<div className='mb-2 me-2'>
											<StarRatings rating={ratingAverage || 0} starRatedColor='#FFCD3C' starDimension='18px' starSpacing='1px' />
										</div>
										<span className='text-muted'>({formatStarRatingCount(ratingCount)} Reviews)</span>
									</div>

									{starRating?.map((item) => (
										<div key={item?.key} className="d-flex align-items-center justify-content-between mb-3">
											<span className="me-2">{item?.key} <span className="text-warning">&#9733;</span></span>
											<div className="progress flex-grow-1 me-2" style={{ height: '10px' }}>
												<div
													className="progress-bar bg-warning"
													role="progressbar"
													style={{ width: `${calculatePercentage(item?.rating_count?.value || 0)}%` }}
													aria-valuenow={calculatePercentage(item?.rating_count?.value || 0)}
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
											<div style={{ minWidth: "9%" }}>
												<span>{item?.rating_count?.value}</span>
												{ratingCount ?
													<span className='ms-1 text-muted' style={{ fontSize: "small" }}> ({((item?.rating_count?.value / ratingCount) * 100).toFixed(1)}%) </span> : <></>}
											</div>
										</div>
									))}
								</div>
							</Col>
							<Col>
								{(npsRating[0]?.count || npsRating[1]?.count || npsRating[2]?.count) ?
									<Row md={1} lg={2} className='py-3 text-center align-items-center'>
										<Col className="chart-container py-3" >
											<DoughnutChart
												backgroundColor={["#00A76F", "#FEC203", "#E84521"]}
												data={{
													labels: ['Promoters', 'Passives', 'Detractors'],
													datasets: [npsRating[0]?.count, npsRating[1]?.count, npsRating[2]?.count],
												}}
												centerText={{ text: npsAverage ?? "-", subText: 'NPS' }}
												height={200}
												hideLabel={true}
											/>
										</Col>
										<Col className="nps-summary d-flex flex-column justify-content-center">
											{npsRating?.map((item) => (
												<div key={item.label} className="d-flex align-items-center justify-content-center justify-content-lg-start">
													<span
														className="me-2"
														style={{
															width: '12px',
															height: '12px',
															backgroundColor: item?.color,
															borderRadius: '50%',
															display: 'inline-block',
														}}
													></span>
													<span className="me-2">{item?.label}</span>
													<span className="fw-bold me-2">{item?.percentage?.toFixed(1)}%</span>
													<span className="text-muted">({item?.count})</span>
												</div>
											))}
										</Col>
									</Row> :
									<div className='d-flex align-items-center justify-content-center h-100 text-muted text-sm'>No NPS data found</div>
								}
							</Col>
						</Row>
					</Spinner>
				</Card>
				<Row>
					<Col md={12} lg={8}>
						<Card>
							<CardHeader>
								Locations
							</CardHeader>
							<Spinner display={spinner}>
								<Table responsive size='sm' className='mb-0' >
									<thead>
										<tr>
											<th className='text-left cursor-pointer' data-sort="tables-row" onClick={() => { onSort("shipper_name.hits.hits.0._source.shipper.name", shipperRating, setShipperRating) }}>Location</th>
											<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_rating.value", shipperRating, setShipperRating) }}>Rating</th>
											<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_nps.value", shipperRating, setShipperRating) }}>Nps</th>
											<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_response_rate", shipperRating, setShipperRating) }}>Response Rate</th>
										</tr>
									</thead>
									<tbody>
										{shipperRating?.map((rating) => {
											return (
												<>
													< tr key={rating?.key}>
														<td className='text-left'>{rating?.shipper_name?.hits?.hits[0]?._source?.shipper?.name}</td>
														<td className='text-center'>{rating?.avg_rating?.value ? <><span className="text-warning"> &#9733; </span>{rating?.avg_rating?.value.toFixed(2)}</> : "-"}</td>
														<td className='text-center'>{rating?.avg_nps?.value ? rating?.avg_nps?.value : 0}</td>
														<td className='text-center'>{rating?.avg_response_rate ? `${rating?.avg_response_rate}%` : "-"}</td>
													</tr >

												</>
											)
										})
										}
									</tbody>
								</Table>
							</Spinner>
						</Card>
					</Col>
					<Col md={12} lg={4}>
						<Card>
							<CardHeader>
								Courier
							</CardHeader>
							<Spinner display={spinner}>
								<Table responsive size='sm' className='mb-0' >
									<thead>
										<tr>
											<th className='text-start cursor-pointer' data-sort="tables-row" onClick={() => { onSort("key", carrierRating, setCarrierRating) }}>Courier</th>
											<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_rating.value", carrierRating, setCarrierRating) }}>Rating</th>
										</tr>
									</thead>
									<tbody>
										{carrierRating?.map((rating, index) => {
											return (
												< tr key={rating?.key} >
													<td className='text-start'>{rating?.key}</td>
													<td className='text-center'>{rating?.avg_rating?.value ? (<><span className="text-warning"> &#9733; </span>{rating?.avg_rating?.value.toFixed(2)}</>) : "-"}</td>
												</tr >
											)
										})
										}
									</tbody>
								</Table>
							</Spinner>
						</Card>
					</Col>
				</Row>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm' className='mb-0' >
							<thead>
								<tr>
									<th className='text-start'>Shipment</th>
									<th className='text-start'>Reviews</th>
									<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { handleElasticSort("ratings.items.rating") }}>Rating</th>
									<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { handleElasticSort("ratings.items.nps") }}>NPS</th>
									<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { handleElasticSort("ratings.items.createdAt") }}>Date</th>
								</tr>
							</thead>
							<tbody>
								{
									ratings?.length ? (ratings?.map((rating) => {
										return (
											(rating?._source?.ratings?.items[0]?.feedback || rating?._source?.ratings?.items[0]?.nps || rating?._source?.ratings?.items[0]?.rating) &&
											< tr key={rating?._source?.number}>
												<td style={{ wordWrap: "break-word", maxWidth: "80px" }}>
													<div className="text-muted">#{rating?._source?.number}</div>
													{rating?._source.shipper?.name}
													<div className='small text-muted'>
														{rating?._source?.shipperGroup?.name}
													</div>
												</td>
												<td style={{ wordWrap: "break-word", maxWidth: "200px" }}>
													{rating?._source?.ratings?.items[0]?.feedback || '-'}
													<div className='text-small text-muted text-wrap'>{rating?._source?.shipTo?.name}</div>
												</td>
												<td className='text-center'>{rating?._source?.ratings?.items[0]?.rating ? <><span className="text-warning"> &#9733; </span>{rating?._source?.ratings?.items[0]?.rating}</> : "-"}</td>
												<td className='text-center tables-handle'>
													{rating?._source?.ratings?.items[0]?.nps ? <div className={`mx-auto nps nps-${rating?._source?.ratings?.items[0]?.nps * 10}`}></div> : '-'}
												</td>
												<td className='text-center' style={{ maxWidth: "50px" }}>
													{rating?._source?.ratings?.items[0]?.createdAt ?
														<>
															{moment(rating?._source?.ratings?.items[0]?.createdAt).tz(rating?._source?.shipFrom?.timezone?.id || "America/New_York").format("h:mm a z")}
															<div className='small text-muted'>{moment(rating?._source?.ratings?.items[0]?.createdAt).tz(rating?._source?.shipFrom?.timezone?.id || "America/New_York").format("MMM D, YYYY")}</div>
														</> : "-"}
												</td>
											</tr >

										)
									})) : <tr className="text-muted text-center">
										<td colSpan={5}>No data found</td></tr>
								}
							</tbody>
							<TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={7} />
						</Table>
					</Spinner>
				</Card>
			</Container>
		</>
	);
};

export default RatingDashboard;

