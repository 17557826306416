import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useDispatch, useSelector } from 'react-redux';
import { storeShipper, storeShipperGroup } from '../stores/slice';
import ShipmentStatus from './../data/shipment-status.json';
import { Link } from 'react-router-dom';
import awsconfig from "../aws-exports";
import boxStatus from "../data/box-status.json";

export const TableKeywordSearch = ({ keyword, onChange }) => {
    return (
        <>
            <div className='col' style={{ minWidth: '250px' }}>
                <div className='input-group input-group-sm input-group-flush input-group-merge input-group-reverse'>
                    <input className='form-control list-search' type='text' placeholder='Search' value={keyword || ''} onChange={({ target: { value } }) => { onChange(value) }} />
                    <span className='input-group-text'>
                        <i className='fe fe-search'></i>
                    </span>
                </div>
            </div>
        </>
    )
}

export const TableHomeButton = ({ dark }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Link to='/dashboard' className={`btn btn-sm ${color}`}>
                <i className='fe fe-home' />
            </Link>
        </Col>
    )
}

export const TableFacetFilter = ({ name, item, facets, facetName, dark, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {name}: {item || 'All'}
                </button>
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                    {
                        facets && facets[facetName] && Object.keys(facets[facetName])?.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'accent' })).map((item, index) => {
                            return <button key={index} className='dropdown-item' onClick={() => onChange(item)} >
                                {item} ({facets[facetName][item]})
                            </button>
                        })
                    }
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}

export const TableShipperFilter = ({ dark, hideAll }) => {
    const dispatch = useDispatch();

    const color = dark ? 'btn-dark' : 'btn-light'
    const myShipper = useSelector((state) => state.slice.SHIPPER)
    const myShippers = useSelector((state) => state.slice.SHIPPERS)


    const switchShipper = (shipper) => {
        if (shipper) {
            shipper?.shipper?.id && localStorage.setItem('localShipperID', JSON.stringify(shipper?.shipper?.id))
            dispatch(storeShipper(shipper))
        } else {
            dispatch(storeShipper(myShippers))
        }
    }

    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Location: {myShipper?.shipper?.name || 'All'}
                </button>
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                    {
                        myShippers?.map((item, index) => {
                            return <button key={index} className='dropdown-item' onClick={() => switchShipper(item)} >
                                {item?.shipper?.name}
                            </button>
                        })
                    }
                    {!hideAll &&
                        <>
                            <hr />
                            <button className='dropdown-item' onClick={() => switchShipper(false)} >
                                All Locations
                            </button>
                        </>
                    }

                </div>
            </Dropdown>
        </Col>
    )
}

export const TableShipperGroupFilter = ({ dark }) => {
    const dispatch = useDispatch();
    const color = dark ? 'btn-dark' : 'btn-light'
    const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP)
    const myShipperGroups = useSelector((state) => state.slice.SHIPPER_GROUPS)
    const switchShipper = (shipperGroup) => {
        if (shipperGroup) {
            dispatch(storeShipperGroup(shipperGroup))
        }
    }

    return (
        <>
            {
                myShipperGroups?.length > 0 &&
                <Col className='col-auto px-1 my-1'>
                    <Dropdown className='dropdown'>
                        <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                            Client: {myShipperGroup?.shipperGroup?.name || 'All'}
                        </button>
                        <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                            {
                                myShipperGroups && myShipperGroups?.map((item, index) => {
                                    return <button key={index} className='dropdown-item' onClick={() => switchShipper(item)} >
                                        {item?.shipperGroup?.name}
                                    </button>
                                })
                            }
                        </div>
                    </Dropdown>
                </Col>
            }
        </>
    )
}

export const TableStatusFilter = ({ name, item, facets, facetName, dark, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {name}: {ShipmentStatus[item] || 'All'}
                </button>
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                    {
                        facets && facets[facetName] && Object.keys(facets[facetName])?.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'accent' }))?.map((item, index) => {
                            return <button key={index} className='dropdown-item' onClick={() => onChange(item)} >
                                {ShipmentStatus[item]} ({facets[facetName][item]})
                            </button>
                        })
                    }
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}

export const TableDateFilter = ({ timezone = 'America/New_York', onChange, startOf = 0, isBilling = false, hideRange, singleDatePicker = false, dateFilters }) => {

    const [currentTimezone, setCurrentTimezone] = useState('America/New_York');

    let startDate = moment().tz('America/New_York').subtract(startOf, 'days').startOf('day')
    let endDate = moment().tz('America/New_York').endOf('day')

    const handleDateChange = (event, picker) => {
        const fromDate = moment.tz(picker.startDate.format('YYYY-MM-DD 00:00:00'), timezone).unix()
        const toDate = moment.tz(picker.endDate.format('YYYY-MM-DD 23:59:59'), timezone).unix()
        onChange({ fromDate, toDate })
    }

    let ranges = {
        'Tomorrow': [moment().add(1, 'day'), moment().add(1, 'day')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 14 Days': [moment().subtract(13, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    }

    if (hideRange?.length) {
        hideRange.map((item) => {
            if (ranges[item]) {
                delete ranges[item]
            }
        })

    }

    if (singleDatePicker) {
        delete ranges['Tomorrow'];
        delete ranges['Last 7 Days'];
        delete ranges['Last 14 Days'];
        delete ranges['Last 30 Days'];
        delete ranges['This Month'];
        delete ranges['Last Month'];
    }

    if (isBilling) {
        ranges = {
            'last 1H': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').startOf('month').add(14, 'days')],
            'last 2H': [moment().subtract(1, 'month').startOf('month').add(15, 'days'), moment().subtract(1, 'month').endOf('month')],
            'Current 1H': [moment().startOf('month'), moment().startOf('month').add(14, 'days')],
            'Current 2H': [moment().startOf('month').add(15, 'days'), moment().endOf('month')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if (dateFilters) {
                const time1 = moment.tz(currentTimezone).utcOffset();
                const time2 = moment.tz(timezone).utcOffset();
                const diff = (time1 - time2) * 60 || 0

                const currentStartDate = dateFilters?.fromDate + diff
                const currentToDate = dateFilters?.toDate + diff

                startDate = currentStartDate
                endDate = currentToDate

                onChange({ fromDate: currentStartDate, toDate: currentToDate });
                setCurrentTimezone(timezone)
            }
        }, 300)


    }, [timezone]);

    return (
        <Col className='col-auto px-1 my-1'>
            {
                timezone &&
                <DateRangePicker onApply={handleDateChange}
                    initialSettings={{
                        startDate: startDate,
                        endDate: endDate,
                        showDropdowns: true,
                        alwaysShowCalendars: true,
                        singleDatePicker: singleDatePicker,
                        opens: 'left',
                        locale: { format: 'MMM D, YYYY' },
                        ranges,
                        locale: { format: 'MMM D, YYYY', customRangeLabel: singleDatePicker ? "Custom" : "Custom Range" }
                    }}>
                    <input className='btn btn-sm btn-light' style={{ width: singleDatePicker ? '100px' : '200px' }} />
                </DateRangePicker>
            }
        </Col>
    )
}
export const TimeZoneFilter = ({ title, value, dark, onChange, setTz, tz }) => {
    const color = !dark ? 'btn-dark' : 'btn-light'
    const textColor = dark ? 'text-light' : 'text-dark'
    const statusOptions = [
        { label: 'EST', value: 'America/New_York' },
        { label: 'CST', value: 'America/Chicago' },
        { label: 'MST', value: 'America/Denver' },
        { label: 'PST', value: 'America/Los_Angeles' },
    ]


    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>{title}</div>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {tz || 'EST'}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div>
                        {
                            Object.keys(statusOptions).map((item, index) => {
                                return <button key={index} className='dropdown-item' onClick={() => { onChange(statusOptions[item].value); setTz(statusOptions[item].label) }} >
                                    {statusOptions[item].label}
                                </button>
                            })
                        }
                    </div>
                    {/* <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button> */}
                </div>
            </Dropdown>
        </Col>
    )
}
export const TableClearFilter = ({ dark, onClick }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Button className={`btn ${color} btn-sm pb-0`} onClick={onClick} title='Clear Filter'>
                <i className='fe fe-x-circle'></i>
            </Button>
        </Col>
    )
}

export const TableExportFilter = ({ option, onChange }) => {
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown>
                <Dropdown.Toggle variant='light' size='sm'>Export</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={onChange}>{option}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    )
}

export const GreenPhoxFilter = ({ value, onChange, dark }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    const Options = [
        { label: 'GreenPhox', value: true },
        { label: 'Other', value: false },
    ]

    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Box Type: {value?.label || 'All'}
                </button>
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                    {
                        Options?.map((item, index) => {
                            return <button key={index} className='dropdown-item' onClick={() => onChange(item)} >
                                {item?.label}
                            </button>
                        })
                    }
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}




export const TablePagination = ({ pageNumber, pageCount, setPageNumber, colSpan }) => {
    return (
        <tfoot>
            <tr>
                <td colSpan={colSpan}>
                    {
                        pageCount > 0 ? <ul className='pagination pagination-sm mb-0 justify-content-center'>
                            {
                                pageNumber !== 0 &&
                                <li className='page-item'>
                                    <button className='page-link bg-light' onClick={() => { setPageNumber(pageNumber - 1) }}>
                                        Previous
                                    </button>
                                </li>
                            }

                            <li className='page-item'>
                                <button className='page-link'>Page {pageNumber + 1} of {pageCount}</button>
                            </li>

                            {
                                (pageCount - 1) > pageNumber &&
                                <li className='page-item'>
                                    <button className='page-link bg-light' onClick={() => { setPageNumber(pageNumber + 1); }}>
                                        Next
                                    </button>
                                </li>
                            }
                        </ul> : <div className='text-center'>No records found</div>
                    }
                </td>
            </tr>
        </tfoot>
    )
}



export const BoxStatusFilter = ({ value, dark, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    const textColor = dark ? 'text-light' : 'text-dark'

    const statusOptions = [
        { label: 'Available', value: 'AVAILABLE' },
        { label: 'In Use', value: 'IN_USE' },
        { label: 'Out for Return', value: 'OUT_FOR_RETURN' },
        { label: 'Returned', value: 'RETURNED' },
        { label: 'Damaged', value: 'DAMAGED' },
        { label: 'Retired', value: 'RETIRED' },
    ]

    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Status: {boxStatus[value] || 'All'}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
                        {
                            Object.keys(statusOptions).map((item, index) => {
                                return <button key={index} className='dropdown-item' onClick={() => onChange(statusOptions[item].value)} >
                                    {statusOptions[item].label}
                                </button>
                            })
                        }
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}

export const MapToggleButton = ({ setMapStyle, mapStyle }) => {

    let awsExportMapStryle = Object.keys(awsconfig.geo.amazon_location_service.maps.items)
    const defaultMapStyle = awsExportMapStryle.filter(element => !element.includes('satellite'));
    const satelliteMapStyle = awsExportMapStryle.filter(element => element.includes('satellite'));

    return (
        <div className="btn-group btn-group me-2 mb-3" role="group" aria-label="First group">
            <Button className={`btn btn-${mapStyle === defaultMapStyle.join() ? "dark text-white" : "white"} m-0`} onClick={() => setMapStyle(defaultMapStyle.join())}  >
                <span className="fe fe-map-pin"></span>

            </Button>

            <Button className={`btn btn-${mapStyle === satelliteMapStyle.join() ? "dark text-white" : "white"} m-0`} onClick={() => setMapStyle(satelliteMapStyle.join())} >
                <span className="fe fe-map"></span>
            </Button>
        </div>

    )
}

export const PatientActiveFilter = ({ active, onChange }) => {
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='bg-light rounded'>
                <button className={`btn btn-sm  dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Status :  {active === '' ? 'All' : active === true ? 'Active' : "Inactive"}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <Dropdown.Item onClick={() => onChange(true)}>Active</Dropdown.Item>
                    <Dropdown.Item onClick={() => onChange(false)}>Inactive</Dropdown.Item>
                    <hr />
                    <Dropdown.Item onClick={() => onChange('')}>All</Dropdown.Item>
                </div>
            </Dropdown>
        </Col>
    )
}


export const StatusFilter = ({ value, dark, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    const textColor = dark ? 'text-light' : 'text-dark'


    const statusOptions = [
        { label: 'Ready for Pickup', value: 'READY_FOR_PICKUP' },
        { label: 'Out for Delivery', value: 'OUT_FOR_DELIVERY' },
        { label: 'Delivered', value: 'DELIVERED' },
        { label: 'In Transit', value: 'IN_TRANSIT' },
        { label: 'Out for Return', value: 'OUT_FOR_RETURN' },
        { label: 'Returned', value: 'RETURNED' },
        { label: 'Cancelled', value: 'EXCEPTION' },
        { label: 'Open', value: 'OPEN' },
    ]


    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Status: {ShipmentStatus[value] || 'All'}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div>
                        {
                            Object.keys(statusOptions).map((item, index) => {
                                return <button key={index} className='dropdown-item' onClick={() => onChange(statusOptions[item].value)} >
                                    {statusOptions[item].label}
                                </button>
                            })
                        }
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>

        </Col>
    )
}

export const RatingFilter = ({ value, dark, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    const textColor = dark ? 'text-light' : 'text-dark'

    const ratingOptions = [
        {
            label: (
                <div className='mx-1'>
                    {Array.from({ length: 5 }, (_, i) => (
                        <span className="text-warning"> &#9733; </span>
                    ))}
                </div>
            ),
            value: '5',
        },
        {
            label: (
                <div className='mx-1'>
                    {Array.from({ length: 4 }, (_, i) => (
                        <span className="text-warning"> &#9733; </span>
                    ))}
                </div>
            ),
            value: '4',
        },
        {
            label: (
                <div className='mx-1'>
                    {Array.from({ length: 3 }, (_, i) => (
                        <span className="text-warning"> &#9733; </span>
                    ))}
                </div>
            ),
            value: '3',
        },
        {
            label: (
                <div className='mx-1'>
                    {Array.from({ length: 2 }, (_, i) => (
                        <span className="text-warning"> &#9733; </span>
                    ))}
                </div>
            ),
            value: '2',
        },
        {
            label: (
                <div className='mx-1'>
                    {Array.from({ length: 1 }, (_, i) => (
                        <span className="text-warning"> &#9733; </span>
                    ))}
                </div>
            ),
            value: '1',
        },
    ]

    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle d-flex align-items-center`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Rating: {value?.label || 'All'}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div>
                        {
                            Object.keys(ratingOptions).map((item, index) => {
                                return <button key={index} className='dropdown-item' onClick={() => onChange(ratingOptions[item])} >
                                    {ratingOptions[item].label}
                                </button>
                            })
                        }
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>

        </Col>
    )
}

export const NPSFilter = ({ value, dark, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    const textColor = dark ? 'text-light' : 'text-dark'

    const npsOptions = [
        { label: 'Promoters (9 - 10)', value: 'promoters', color: "#00A76F" },
        { label: 'Passives (7 - 8)', value: 'passives', color: "#FEC203" },
        { label: 'Detractors (0 - 6)', value: 'detractors', color: "#E84521" },
    ]


    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    NPS: {value?.label || 'All'}
                </button>
                <div className={`dropdown-menu dropdown-menu-right}`} aria-labelledby='dropdownMenuButton'  >
                    <div>
                        {
                            Object.keys(npsOptions).map((item, index) => {
                                return <button key={index} className='dropdown-item' style={{ color: npsOptions[item]?.color }} onClick={() => onChange(npsOptions[item])} >
                                    {npsOptions[item].label}
                                </button>
                            })
                        }
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>

        </Col>
    )
}